import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "./common";
import * as types from "./actionTypes";

export const fetchIcons = (token) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const reduxIcons = currentState.icons;
    dispatch({ type: types.FETCHING_ICONS });
    if (reduxIcons && reduxIcons.length > 0) {
      dispatch({ type: types.FETCHED_ICONS, icons: reduxIcons });
    } else {
      return fetch(`${SITEBUILDER_URL}/api/icons`, fetchOptions(token, "GET"))
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred fetching icons.", error);
            dispatch({ type: types.FETCH_ICONS_ERROR });
          }
        )
        .then((json) => {
          if (json.status) {
            const icons = json.data.map((icon) => {
              if (icon.value === "1") {
                icon.checked = true;
              }
              return icon;
            });
            dispatch({ type: types.FETCHED_ICONS, icons: icons });
          } else {
            dispatch({ type: types.FETCH_ICONS_ERROR });
          }
        })
        .catch(function (e) {
          console.log(e);
          dispatch({ type: types.FETCH_ICONS_ERROR });
        });
    }
  };
};
