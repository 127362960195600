import React, { useEffect, useState } from "react";
import Loading from "../controls/Loading";
import { useSelector, useDispatch } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { Redirect } from "react-router-dom";

const Logmein = (props) => {
  const dispatch = useDispatch();
  const authIsLoggedIn = useSelector((state) => state.auth.authIsLoggedIn);
  const authError = useSelector((state) => state.auth.authError);
  const isFetching = useSelector((state) => state.auth.authFetching);
  const token = props.match.params.token;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(signIn(token)).then(() => {
      setIsLoading(false);
    });
  }, [token, dispatch]);

  if (isLoading || isFetching) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (authIsLoggedIn) {
    return <Redirect to="/roster-list" />;
  }

  if (authError || !token) {
    window.location.assign(process.env.REACT_APP_SITEBUILDER_URL);
    //console.log("error");
    return null;
  }
};

export default Logmein;
