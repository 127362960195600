import React from "react";
import { useSelector } from "react-redux";

/**
 * Top nav bar user drop down and greeting
 *
 * @component
 */
const Navgreeting = (props) => {
  const firstName = useSelector((state) => state.auth.authUser.firstName);
  const lastName = useSelector((state) => state.auth.authUser.lastName);

  const style = {
    justifyContent: "flex-end",
    flexEnd: "",
    backgroundColor: "white",
    border: "0px",
  };

  return (
    <ul className="nav navbar-nav pull-right user-greeting">
      <li className="dropdown">
        <button style={style} href="#" className="dropdown-toggle m-t-15" data-toggle="dropdown">
          Hi, {firstName} {lastName} <b className="caret"></b>
        </button>
        <span className="dropdown-arrow"></span>
        <ul className="dropdown-menu">
          <li>
            <a href="/logout">
              Logout
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out">
                <path d="M10 22H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h5" />
                <polyline points="17 16 21 12 17 8" />
                <line x1="21" y1="12" x2="9" y2="12" />
              </svg>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Navgreeting;
