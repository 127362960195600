import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const rosterReducer = (state = initialState.roster, action) => {
  switch (action.type) {
    //save roster settings
    case types.SAVED_ROSTER_SETTINGS:
      return {
        ...state,
        rosterSettingsSaving: false,
        rosterSettingsSaved: true,
        rosterSettingsSaveError: false,
        settings: action.settings,
      };
    case types.ROSTER_SETTINGS_SAVING:
      return {
        ...state,
        rosterSettingsSaving: true,
        rosterSettingsSaved: false,
        rosterSettingsSaveError: false,
      };
    case types.SAVE_ROSTER_SETTINGS_ERROR:
      return {
        ...state,
        rosterSettingsSaving: false,
        rosterSettingsSaved: false,
        rosterSettingsSaveError: true,
      };
    //load agents
    case types.LOADED_ROSTER_AGENTS:
      return {
        ...state,
        agentsFetching: false,
        agentsLoaded: true,
        agents: action.agents,
      };
    case types.LOAD_ROSTER_AGENTS_FETCHING:
      return {
        ...state,
        agentsFetching: true,
        agentsLoaded: false,
        agentsLoadError: false,
        agentDeleteError: false,
        agentUpdatingShowOnRosterError: false,
        agents: [],
      };
    case types.LOAD_ROSTER_AGENTS_ERROR:
      return {
        ...state,
        agentsFetching: false,
        agentsLoaded: false,
        agentsLoadError: true,
      };
    //load roster settings
    case types.LOADED_ROSTER_SETTINGS:
      return {
        ...state,
        rosterSettingsFetching: false,
        rosterSettingsLoaded: true,
        settings: action.settings,
      };
    case types.LOAD_ROSTER_SETTINGS_FETCHING:
      return {
        ...state,
        rosterSettingsFetching: true,
        rosterSettingsLoaded: false,
        rosterSettingsLoadError: false,
      };
    case types.LOAD_ROSTER_SETTINGS_ERROR:
      return {
        ...state,
        rosterSettingsFetching: false,
        rosterSettingsLoaded: false,
        rosterSettingsLoadError: true,
      };
    //load offices
    case types.LOADED_ROSTER_OFFICES:
      return {
        ...state,
        officesFetching: false,
        officesLoaded: true,
        offices: action.offices,
      };
    case types.LOAD_ROSTER_OFFICES_FETCHING:
      return {
        ...state,
        officesFetching: true,
        officesLoaded: false,
        officesLoadError: false,
        offices: [],
      };
    case types.LOAD_ROSTER_OFFICES_ERROR:
      return {
        ...state,
        agentsFetching: false,
        agentsLoaded: false,
        agentsLoadError: true,
      };
    //delete agent
    case types.DELETE_ROSTER_AGENT_ERROR:
      return {
        ...state,
        agentDeleteError: true,
        agentDeleting: false,
      };
    case types.DELETING_ROSTER_AGENT:
      return {
        ...state,
        agentDeleteError: false,
        agentDeleting: true,
      };
    case types.DELETED_ROSTER_AGENT:
      return {
        ...state,
        agentDeleteError: false,
        agentDeleting: false,
        agents: action.agents,
      };
    //update show on roster
    case types.UPDATING_SHOW_ON_ROSTER_AGENT:
      return {
        ...state,
        agentUpdatingShowOnRosterError: false,
        agentUpdatingShowOnRoster: true,
      };
    case types.UPDATE_SHOW_ON_ROSTER_AGENT_ERROR:
      return {
        ...state,
        agentUpdatingShowOnRosterError: true,
        agentUpdatingShowOnRoster: false,
      };
    case types.UPDATED_SHOW_ON_ROSTER_AGENT:
      return {
        ...state,
        agentUpdatingShowOnRosterError: false,
        agentUpdatingShowOnRoster: false,
        agents: action.agents,
      };
    //update featured
    case types.UPDATING_FEATURED_AGENT:
      return {
        ...state,
        agentUpdatingFeaturedError: false,
        agentUpdatingFeatured: true,
      };
    case types.UPDATE_FEATURE_AGENT_ERROR:
      return {
        ...state,
        agentUpdatingFeaturedError: true,
        agentUpdatingFeatured: false,
      };
    case types.UPDATED_FEATURE_AGENT:
      return {
        ...state,
        agentUpdatingFeaturedError: false,
        agentUpdatingFeatured: false,
        agents: action.agents,
      };

    default:
      return state;
  }
};

export default rosterReducer;
