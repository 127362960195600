import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Generates the header with what results are being displayed and total results.
 *
 * @component
 */
const Pagination = ({ totalResults, pageResults, perPageResults, page, handlePageChange }) => {
  if (totalResults < 2) {
    return null;
  }

  const showPrev = page > 1 ? true : false;
  const showNext = pageResults * page < totalResults ? true : false;
  const totalPages = totalResults / perPageResults + 1;

  const prePage = (page, totalPages) => {
    let pagesResults = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i === page) {
        pagesResults.push(
          <li className="disabled active" key={i}>
            <Link to="#">{i}</Link>
          </li>
        );
      } else {
        pagesResults.push(
          <li key={i}>
            <Link to="#" onClick={() => handlePageChange(i)}>
              {i}
            </Link>
          </li>
        );
      }
    }
    return pagesResults;
  };

  return (
    <nav className="page-nav" aria-label="Page navigation">
      <ul className="pagination">
        {showPrev && (
          <li>
            <Link className="pagination-prev" to="#" onClick={() => handlePageChange(page - 1)} aria-label="Previous">
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="15 18 9 12 15 6" />
                </svg>
              </span>
            </Link>
          </li>
        )}
        {prePage(page, totalPages)}
        {showNext && (
          <li>
            <Link className="pagination-next" to="#" aria-label="Next" onClick={() => handlePageChange(page + 1)}>
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="9 18 15 12 9 6" />
                </svg>
              </span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  /**
   * total results obtained
   */
  totalResults: PropTypes.number.isRequired,
  /**
   * total results on current page obtained
   */
  pageResults: PropTypes.number.isRequired,
  /**
   * total results expected per page
   */
  perPageResults: PropTypes.number.isRequired,
  /**
   * current page
   */
  page: PropTypes.number.isRequired,
  /**
   * function to do the page change and load new results
   */
  handlePageChange: PropTypes.func.isRequired,
};

export default Pagination;
