import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * social section of an agent edit
 *
 * @component
 */

const AgentSocial = ({ social, handleSocialChanges, errors }) => {
  const [socialState, setSocialState] = useState(social);

  const handleOnChange = (index, val) => {
    const newState = { ...socialState, [index]: val };
    setSocialState(newState);
    handleSocialChanges(newState);
  };

  const style = {
    padding: "5px",
    width: "28px",
    height: "28px",
  };

  const socialList = ["facebook", "twitter", "linkedin", "youtube", "pinterest", "instagram"];
  const inputs = socialList.map((entry, index) => {
    return (
      <div className="input-wrapper" key={index}>
        <label htmlFor={entry}>{entry.charAt(0).toUpperCase() + entry.slice(1)}</label>
        <input
          placeholder="Enter URL..."
          type="text"
          name={entry}
          onChange={(e) => {
            handleOnChange(entry, e.target.value);
          }}
          value={socialState[entry]}
        />
        {errors[entry] && <div className="error">{errors[entry]}</div>}
      </div>
    );
  });

  return (
    <div className="info-group-container m-b-30">
      <div className="info-group-col">
        <div className="info-group">
          <div className="header">
            <div className="header-label">
              <img src="/images/icons/share-2.svg" alt="Social" style={style} />
              <span>Social</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group m-b-30">
              {inputs[0] || null}
              {inputs[1] || null}
            </div>
            <div className="input-group m-b-30">
              {inputs[2] || null}
              {inputs[3] || null}
            </div>
            <div className="input-group m-b-30">
              {inputs[4] || null}
              {inputs[5] || null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentSocial.propTypes = {
  /**
   * social info object
   * {
   * "facebook": "fb.com/user1",
   * "twitter": "tw.com/user1",
   * "instagram": "ins.com/user1",
   * "youtube": "yt.com/user1",
   * "linkedin": "ln.com/user1",
   * "pinterest": "pin.com/user1"
   * }
   */
  social: PropTypes.object.isRequired,
  /**
   * function that handles the image reorder save
   */
  handleSocialChanges: PropTypes.func.isRequired,
  /**
   * possible errors on input data
   */
  errors: PropTypes.object.isRequired,
};

export default AgentSocial;
