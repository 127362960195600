import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "./common";
import * as types from "./actionTypes";

export const signIn = (loginToken) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_IS_FETCHING });

    return fetch(`${SITEBUILDER_URL}/api/login?token=${loginToken}`)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred login in.", error);
          dispatch({ type: types.LOGIN_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.LOGIN_SUCCESS, user: json.data });
        } else {
          dispatch({ type: types.LOGIN_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.LOGIN_ERROR });
      });
  };
};

export const isTokenValid = (token) => {
  return (dispatch) => {
    dispatch({ type: types.LOGIN_IS_FETCHING });

    return fetch(`${SITEBUILDER_URL}/api/is-token-valid`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred validating token.", error);
          dispatch({ type: types.LOGIN_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.LOGIN_TOKEN_IS_VALID });
        } else {
          dispatch({ type: types.LOGIN_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.LOGIN_ERROR });
      });
  };
};
