import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

/**
 * Drag and drop input file, uses react-dropzone
 *
 * @component
 * @example
 * return (
 *   <DragAndDropFileInput onDrop={()=>{}} accept={"image/*"} maxSize={10485760} multiple={false} />
 * )
 */

const DragAndDropFileInput = ({ onDrop, accept, maxSize, multiple, dragText, extraStyle, dragTextStyle }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
    paddingTop: "25px",
    paddingBottom: "5px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...extraStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, baseStyle, activeStyle, rejectStyle, extraStyle]
  );

  return (
    <div {...getRootProps({ style })}>
      <input className="dropzone-input" {...getInputProps()} />
      <div style={dragTextStyle} className="text-center">
        {isDragActive ? <p className="dropzone-content">Release to drop the files here</p> : <p className="dropzone-content">{dragText}</p>}
      </div>
    </div>
  );
};

DragAndDropFileInput.defaultProps = {
  extraStyle: {},
  dragTextStyle: {},
};

DragAndDropFileInput.propTypes = {
  /**
   * function to handle what happens when a file is dropped
   */
  onDrop: PropTypes.func.isRequired,
  /**
   * files types that are allowed: ex "image/*"
   */
  accept: PropTypes.string.isRequired,
  /**
   * max file size allowed, ex 10 MB = 10485760
   */
  maxSize: PropTypes.number.isRequired,
  /**
   * multi file upload allowed
   */
  multiple: PropTypes.bool.isRequired,
  /**
   * text that shows before dragging a file
   */
  dragText: PropTypes.string.isRequired,
  /**
   * extra style object
   */
  extraStyle: PropTypes.object,
  /**
   * style from drag file text
   */
  dragTextStyle: PropTypes.object,
};

export default DragAndDropFileInput;
