import React from "react";
import PropTypes from "prop-types";

/**
 * tabs component that receives a list of tabs and tabPanes with each tab's content
 *
 * @component
 */
const Tabs = ({ list, activeTab, children, handleChange }) => {
  const style = {
    padding: "5px",
    width: "28px",
    height: "28px",
  };

  const handleOnClick = (id) => {
    handleChange(id);
  };

  const tabList = list.map((tab) => {
    const active = activeTab === tab.dataTab ? "active" : "";
    return (
      <div className={`tab-trigger ${active}`} onClick={() => handleOnClick(tab.dataTab)} data-tab={tab.dataTab} key={tab.id}>
        <img style={style} src={tab.icon} alt={tab.title} />
        <span>{tab.title}</span>
      </div>
    );
  });

  return (
    <div>
      <div className="tab-header bg-white">{tabList}</div>
      {children}
    </div>
  );
};

Tabs.propTypes = {
  /**
   * list of tabs
   * [
   *   { id: 1, title: "About", dataTab: "agent-about", icon: "/images/icons/user.svg" },
   *   { id: 2, title: "Media", dataTab: "agent-media", icon: "/images/icons/image.svg" }
   * ]
   */
  list: PropTypes.array.isRequired,
  /**
   * id of the active tab to display
   */
  activeTab: PropTypes.string.isRequired,
  /**
   * content to display
   */
  children: PropTypes.array.isRequired,
  /**
   * function to handle tab change
   */
  handleChange: PropTypes.func.isRequired,
};

export default Tabs;
