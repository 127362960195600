import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Wrapper for a nav link that uses react-router-dom
 *
 * @component
 */

const Navlink = ({ title, link, active, icon, reactRouter }) => {
  const style = {
    padding: "5px",
    width: "28px",
    height: "28px",
  };

  let navLink = (
    <a href={link}>
      <img style={style} src={icon} alt={title} />
      {title}
    </a>
  );

  if (reactRouter) {
    navLink = (
      <NavLink to={link}>
        <img style={style} src={icon} alt={title} />
        {title}
      </NavLink>
    );
  }

  return <li className={active ? "active" : ""}>{navLink}</li>;
};

Navlink.defaultProps = {
  active: false,
};

Navlink.propTypes = {
  /**
   * text to display
   */
  title: PropTypes.string.isRequired,
  /**
   * link to go to
   */
  link: PropTypes.string.isRequired,
  /**
   * is it the current selected option
   */
  active: PropTypes.bool.isRequired,
  /**
   * icon to display next to text
   */
  icon: PropTypes.string.isRequired,
  /**
   * if it should be a normal link or a react router one
   */
  reactRouter: PropTypes.bool,
};

export default Navlink;
