import React from "react";
import PropTypes from "prop-types";

/**
 * Generates the header with what results are being displayed and total results ex: 1 - 20 of 212 agents
 *
 * @component
 * @example
 * const totalResults = 200
 * const pageResults = 20
 * const perPageResults = 20
 * const page = 1
 * const label = "agents"
 * return (
 *   <PaginationHeader totalResults={totalResults} pageResults={pageResults} perPageResults={perPageResults} page={page} label={label} />
 * )
 */
const PaginationHeader = ({ totalResults, pageResults, perPageResults, page, label }) => {
  if (totalResults === 0) {
    return <div>{`0 of ${totalResults} ${label}`}</div>;
  }

  if (totalResults === 1) {
    return <div>{`1 of ${totalResults} ${label}`}</div>;
  }

  const start = (page - 1) * perPageResults + 1;
  const end = start + pageResults - 1;

  return <div>{`${start} - ${end} of ${totalResults} ${label}`}</div>;
};

PaginationHeader.propTypes = {
  /**
   * total results obtained
   */
  totalResults: PropTypes.number.isRequired,
  /**
   * total results on current page obtained
   */
  pageResults: PropTypes.number.isRequired,
  /**
   * total results expected per page
   */
  perPageResults: PropTypes.number.isRequired,
  /**
   * current page
   */
  page: PropTypes.number.isRequired,
  /**
   * label to display after total results
   */
  label: PropTypes.string.isRequired,
};

export default PaginationHeader;
