import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { fetchIcons } from "../../store/actions/iconActions";

/**
 * other links section of an agent edit
 *
 * @component
 */

const AgentLinksOther = ({ links, handleOnAdd, handleOnDelete, title, icon, errors }) => {
  const [addLinkTitle, setAddLinkTitle] = useState("");
  const [addLinkURL, setAddLinkURL] = useState("");
  const [chosenIconValue, setChosenIconValue] = useState("1");
  const [chosenIcon, setChosenIcon] = useState('<a class="fa fa-adjust"></a>');
  const [curatedIconList, setCuratedIconList] = useState([]);
  const iconList = useSelector((state) => state.icon.icons);
  const iconsFetching = useSelector((state) => state.icon.iconsFetching);
  const iconsFetched = useSelector((state) => state.icon.iconsFetched);
  const iconsFetchError = useSelector((state) => state.icon.iconsFetchError);
  const authToken = useSelector((state) => state.auth.authUser.token);
  const dispatch = useDispatch();

  const style = {
    padding: "5px",
    width: "28px",
    height: "28px",
  };

  if (!iconsFetching && !iconsFetched && !iconsFetchError) {
    dispatch(fetchIcons(authToken));
  }

  const handleAddLinkClick = () => {
    let icon = chosenIcon.replace('<a class="fa ', "");
    icon = icon.replace('"></a>', "");
    handleOnAdd(chosenIconValue, addLinkTitle, addLinkURL, icon);
    setAddLinkTitle("");
    setAddLinkURL("");
  };

  const handleAddIconChange = (value, icon) => {
    setChosenIconValue(value);
    setChosenIcon(`<a class="fa ${icon}"></a>`);
  };

  const deleteButton = (linkId) => {
    return (
      <div className="f-right" onClick={() => handleOnDelete(linkId)}>
        <svg data-tippy="Remove" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 action-svg-delete">
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      </div>
    );
  };

  const addedRows = links.map((entry, index) => {
    let iconClass = `fa ${entry.icon} other-links-icon`;

    return (
      <div key={entry.id} className="other-links">
        <i className={iconClass}></i>
        <div className="other-links-name">{entry.title}</div>
        {deleteButton(entry.id)}
      </div>
    );
  });

  useEffect(() => {
    let curatedIcons = iconList.map((icon, index) => {
      let iconClass = `fa ${icon.name} fa-lg`;
      const style = {
        marginTop: "5px",
        marginLeft: "5px",
      };
      return (
        <li style={style} onClick={() => handleAddIconChange(icon.value, icon.name)} key={index}>
          <i className={iconClass}></i>
        </li>
      );
    });
    setCuratedIconList(curatedIcons);
  }, [iconList]);

  return (
    <div className="info-group-container">
      <div className="info-group-col">
        <div className="info-group">
          <div className="header">
            <div className="header-label">
              <img src={icon} alt={title} style={style} />
              <span>{title}</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <label htmlFor="linkTitle">Add Link</label>
            </div>
            <div className="input-group m-b-30">
              <div className="input-wrapper flex2">
                <div className="dropdown">
                  <button className="btn btn-default dropdown-toggle icon-stat-button" type="button" id="addLinkIcon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <span className="selected-icon" dangerouslySetInnerHTML={{ __html: chosenIcon }}></span>
                    <span className="caret m-l-10"></span>
                  </button>
                  <ul className="dropdown-menu icon-stat-dropdown" aria-labelledby="addLinkIcon">
                    {curatedIconList}
                  </ul>
                </div>
              </div>
              <div className="input-wrapper flex2">
                <input
                  placeholder="Enter Title..."
                  type="text"
                  name="linkTitle"
                  value={addLinkTitle}
                  onChange={(event) => {
                    setAddLinkTitle(event.target.value);
                  }}
                />
                {errors.title && <div className="error">{errors.title}</div>}
              </div>
              <div className="input-wrapper flex2">
                <input
                  placeholder="Enter URL..."
                  type="text"
                  name="linkURL"
                  value={addLinkURL}
                  onChange={(event) => {
                    setAddLinkURL(event.target.value);
                  }}
                />
                {errors.url && <div className="error">{errors.url}</div>}
              </div>
              <div className="input-wrapper other-links-add-btn" onClick={handleAddLinkClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="16"></line>
                  <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
              </div>
            </div>
            <div className="input-group m-b-30">{addedRows}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentLinksOther.propTypes = {
  /**
   * [{id: "3", icon: "fa-skype", title: "Blueroof", url: "blueroof360.com"},...]
   * }
   */
  links: PropTypes.array.isRequired,
  /**
   * function that handles the image reorder save
   */
  handleOnDelete: PropTypes.func.isRequired,
  /**
   * function that handles the add other link
   */
  handleOnAdd: PropTypes.func.isRequired,
  /**
   * possible errors on input data
   */
  errors: PropTypes.object.isRequired,
  /**
   * display title and alt
   */
  title: PropTypes.string.isRequired,
  /**
   * path to icon to use on label
   */
  icon: PropTypes.string.isRequired,
};

export default AgentLinksOther;
