import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { validateAgent } from "../validation/AgentValidation";
import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import { useSelector } from "react-redux";
import PhoneForm from "../phone/PhoneForm";
import EmailForm from "../email/EmailForm";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

/**
 * Agent add form, used as body on a modal
 *
 * @component
 * @example
 * return (
 *   <AgentAdd handleClose={() => {}} />
 * )
 */

const AgentAdd = ({ handleClose }) => {
  const agentInit = {
    about: {
      firstName: "",
      lastName: "",
      password: "",
      title: "",
      keywords: "",
      biography: "",
      favorites: "",
      qualifications: "",
      agentMlsID: "",
      showBio: "1",
      videoUrl: "",
      website: "",
      licenseID: "",
    },
    phones: [{ id: "newPhone", number: "", type: "cellular" }],
    emails: [{ id: "newEmail", email: "", type: "work" }],
    addresses: [],
    media: [],
    social: {
      facebook: "",
      twitter: "",
      linkedin: "",
      youtube: "",
      pinterest: "",
      instagram: "",
    },
    testimonials: [],
    counterStats: [{}, {}, {}],
    errors: {},
  };

  const authToken = useSelector((state) => state.auth.authUser.token);
  const brUserId = useSelector((state) => state.auth.authUser.brUserId);
  const [firstName, setFirstName] = useState(agentInit.about.firstName);
  const [lastName, setLastName] = useState(agentInit.about.lastName);
  const [password, setPassword] = useState(agentInit.about.password);
  const [phoneList, setPhoneList] = useState(agentInit.phones);
  const [emailList, setEmailList] = useState(agentInit.emails);
  const [agent, setAgent] = useState(agentInit);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const isEdit = false;

  const handleOnSaveClick = () => {
    let tempAgent = agent;
    tempAgent.about.firstName = firstName;
    tempAgent.about.lastName = lastName;
    tempAgent.about.password = password;
    tempAgent.phones = phoneList;
    tempAgent.emails = emailList;
    setAgent(tempAgent);

    const errors = validateAgent(tempAgent);

    if (Object.keys(errors).length === 0) {
      setIsSaving(true);
      let postOptions = fetchOptions(authToken, "POST");
      let data = new FormData();
      data.append("data", JSON.stringify(setAddAgentData(tempAgent)));
      postOptions["body"] = data;

      return fetch(`${SITEBUILDER_URL}/api/agent`, postOptions)
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred saving new agent.", error);
            toast.error("An error occurred saving new agent.");
            setIsSaving(false);
          }
        )
        .then((json) => {
          if (json.status) {
            handleClose();
            history.push(`/agent/${json.data.user_id}`);
          } else {
            toast.error("Error adding new agent.");
            setIsSaving(false);
          }
        })
        .catch(function (e) {
          console.log(e);
          toast.error("Error adding new agent.");
          setIsSaving(false);
        });
    } else {
      toast.error("Data incomplete, please check the form for errors.");
      setAgent({ ...agent, errors: errors });
    }
  };

  const setAddAgentData = (agentData) => {
    let postData = {
      brUserID: brUserId,
      f_name: agentData.about.firstName,
      l_name: agentData.about.lastName,
      password: agentData.about.password,
      title: agentData.about.title,
      keywords: agentData.about.keywords,
      biography: agentData.about.biography,
      favorites: agentData.about.favorites,
      qualifications: agentData.about.qualifications,
      agent_id: agentData.about.agentMlsID,
      emails: agentData.emails,
      phones: agentData.phones,
      addresses: agentData.addresses,
      social: agentData.social,
      counterStats: agentData.counterStats,
    };
    return postData;
  };

  const handlePhoneChange = (phoneObj) => {
    let tempList = updateList(phoneList, phoneObj);
    setPhoneList(tempList);
  };

  const handleEmailChange = (emailObj) => {
    let tempList = updateList(emailList, emailObj);
    setEmailList(tempList);
  };

  const updateList = (list, updatedObj) => {
    let newList = [];

    if (list.length === 0) {
      newList.push(updatedObj);
    } else {
      newList = list.map((item) => {
        if (item.id === updatedObj.id) {
          item = updatedObj;
        }
        return item;
      });
    }
    return newList;
  };

  let phoneForms = phoneList.map((phone, index) => {
    let phoneError = agent.errors.phones && [index] in agent.errors.phones ? agent.errors.phones[index] : {};
    return <PhoneForm key={phone.id} phone={phone} errors={phoneError} isEdit={isEdit} handleOnChange={handlePhoneChange} />;
  });

  let emailForms = emailList.map((email, index) => {
    let emailError = agent.errors.emails && [index] in agent.errors.emails ? agent.errors.emails[index] : {};
    return <EmailForm key={email.id} email={email} errors={emailError} isEdit={isEdit} handleOnChange={handleEmailChange} />;
  });

  return (
    <div className="main-page-content m-b-50">
      <div className="info-group-col">
        <div className="info-group m-b-30">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Name Settings</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <label htmlFor="first-name">First Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  id="firstName"
                  placeholder="Enter First Name"
                  value={firstName}
                />
                {agent.errors.firstName && <div className="error">{agent.errors.firstName}</div>}
              </div>
              <div className="input-wrapper">
                <label htmlFor="last-name">Last Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  id="lastName"
                  placeholder="Enter Last Name"
                  value={lastName}
                />
                {agent.errors.lastName && <div className="error">{agent.errors.lastName}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="info-group m-b-30">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Password</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <label htmlFor="password">Password*</label>
                <input
                    type="password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    id="password"
                    placeholder="Enter Password"
                    value={password}
                />
                {agent.errors.password && <div className="error">{agent.errors.password}</div>}
              </div>
            </div>
          </div>
        </div>
        <div id="phone-wrapper" className="info-group m-0 m-b-30">
          {phoneForms}
        </div>
        <div id="email-wrapper" className="info-group m-0 m-b-30">
          {emailForms}
        </div>

        {isSaving ? (
          <div className="flex jc-center">
            <button className="icon-btn m-r-15" disabled>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              <span>Cancel</span>
            </button>
            <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn" disabled>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save">
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
              <span>Saving...</span>
            </button>
          </div>
        ) : (
          <div className="flex jc-center">
            <button className="icon-btn m-r-15" onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              <span>Cancel</span>
            </button>
            <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save">
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
              <span>Save</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

AgentAdd.propTypes = {
  /**
   * function to handle close button click
   */
  handleClose: PropTypes.func.isRequired,
};

export default AgentAdd;
