import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Toolbar for the agent edit page, displays the agent name, a view and save button
 *
 * @component
 */

const AgentEditToolbar = ({ name, isEdit, isSaving, handleOnSaveClick, viewUrl }) => {
  const handleOnViewClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="toolbar">
      <div className="toolbar-left">
        <Link to={"/roster-list/"} className="back-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span>Back</span> 
        </Link>
        <h2>
          {isEdit ? "Editing" : "New Agent"}: <span>{name}</span>
        </h2>
      </div>
      <div className="toolbar-right">
        {isEdit && viewUrl && (
          <button onClick={() => handleOnViewClick(viewUrl)} className="btn-primary btn icon-btn m-r-15">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <span>View</span>
          </button>
        )}
        {isSaving ? (
          <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn" disabled>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save">
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg>
            <span>Saving...</span>
          </button>
        ) : (
          <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save">
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg>
            <span>Save</span>
          </button>
        )}
      </div>
    </div>
  );
};

AgentEditToolbar.defaultProps = {
  viewUrl: null,
};

AgentEditToolbar.propTypes = {
  /**
   * name of the agent being edited
   */
  name: PropTypes.string.isRequired,
  /**
   * if is an edit show the view button, also passes this prop to children
   */
  isEdit: PropTypes.bool.isRequired,
  /**
   * if true disables the save button and changes the text to Saving...
   */
  isSaving: PropTypes.bool.isRequired,
  /**
   * function that calls the api to save the edit changes
   */
  handleOnSaveClick: PropTypes.func.isRequired,
  /**
   *
   */
  viewUrl: PropTypes.string,
};

export default AgentEditToolbar;
