import { validEmail } from "./Common";

export const validateLender = (lender) => {
  lender.errors = {};
  if (!lender.firstName || lender.firstName === "") {
    lender.errors.firstName = "First name is required";
  }
  if (!lender.lastName || lender.lastName === "") {
    lender.errors.lastName = "Last name is required";
  }
  if (!lender.email.email || lender.email.email === "") {
    lender.errors.email = "Email is required";
  } else if (!validEmail(lender.email.email)) {
    lender.errors.email = "Email is invalid";
  }
  if (!lender.email.email || lender.email.email === "") {
    lender.errors.email = "Email is required";
  }
  if (lender.phone.number !== "" && lender.phone.number.length !== 10) {
    lender.errors.phone = "Phone is invalid";
  }
  return lender.errors;
};
