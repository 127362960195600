import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const iconReducer = (state = initialState.icon, action) => {
  switch (action.type) {
    case types.FETCHING_ICONS:
      return {
        ...state,
        iconsFetching: true,
        iconsFetchError: false,
        iconsFetched: false,
      };
    case types.FETCH_ICONS_ERROR:
      return {
        ...state,
        iconsFetching: false,
        iconsFetchError: true,
        iconsFetched: false,
      };
    case types.FETCHED_ICONS:
      return {
        ...state,
        iconsFetching: false,
        iconsFetchError: false,
        iconsFetched: true,
        icons: action.icons,
      };

    default:
      return state;
  }
};

export default iconReducer;
