import React, { useState } from "react";
import Dropdown from "../controls/Dropdown";
import { emailTypes } from "../common/DropdownTypes";
import PropTypes from "prop-types";

/**
 * Email form container with email, type and delete
 *
 * @component
 */

const EmailForm = ({ email, errors, handleOnChange, isEdit, handleOnDelete }) => {
  const [emailAddress, setEmail] = useState(email.email);

  const handleTypeChange = (newType) => {
    handleOnChange({ ...email, type: newType });
  };

  const handleEmailChange = (e) => {
    let tempEmail = email;
    email.email = e.target.value;
    handleOnChange(tempEmail);
    setEmail(e.target.value);
  };

  return (
    <div id="email-details-grid" className="info-group" key={email.id}>
      <div className="header">
        <div className="header-label">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
          <span>Email</span>
        </div>
        {isEdit && (
          <div onClick={() => handleOnDelete(email.id)} className="header-actions">
            <svg
              data-tippy="Remove"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-trash-2 action-svg-delete"
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        )}
      </div>
      <div className="data-collection">
        <div className="input-group">
          <input type="hidden" name="emailId" value={email.id} />
          <div className="input-wrapper flex2">
            <label htmlFor="email">Email*</label>
            <input onChange={handleEmailChange} type="text" name="email" placeholder="email@email.com" value={emailAddress} />
            {errors.email && <div className="error">{errors.email}</div>}
          </div>
          <div className="input-wrapper">
            <label htmlFor="email-type">Type*</label>
            <Dropdown list={emailTypes} name={`emailType${email.id}`} handleOnChange={handleTypeChange} selected={email.type} title="Type" />
            {errors.type && <div className="error">{errors.type}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

EmailForm.propTypes = {
  /**
   * object for an email
   * {default: "1", email: "email@br360.com", id: "email@br360.com", type: "work"}
   */
  email: PropTypes.object.isRequired,
  /**
   * object of errors
   * {email: "Email is required", type: "Type is required"}
   */
  errors: PropTypes.object.isRequired,
  /**
   * used to display or hide the delete button
   */
  isEdit: PropTypes.bool.isRequired,
  /**
   * function to handle tab change
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * function to handle the delete of email
   */
  handleOnDelete: PropTypes.func,
};

export default EmailForm;
