import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { loadRosterOffices } from "../../store/actions/rosterActions";
import Dropdown from "../controls/Dropdown";

/**
 * agent offices section for edit agent
 *
 * @component
 */
const AgentOffices = ({ agentOffices, handleOnAdd, handleOnDelete, isAdding }) => {
  const authToken = useSelector((state) => state.auth.authUser.token);
  const brUserId = useSelector((state) => state.auth.authUser.brUserId);
  //offices list
  const officesFetching = useSelector((state) => state.roster.officesFetching);
  const offices = useSelector((state) => state.roster.offices);
  //dispatch
  const dispatch = useDispatch();

  const [officeSelected, setOfficeSelected] = useState("");

  useEffect(() => {
    dispatch(loadRosterOffices(brUserId, authToken));
  }, [brUserId, authToken, dispatch]);

  const handleOnAddLocal = (office_id) => {
    let selectedOffice = { office_id };

    for (let i = 0; i < offices.length; i++) {
      if (offices[i].value === office_id) {
        selectedOffice.office_name = offices[i].name;
        break;
      }
    }
    handleOnAdd(selectedOffice);
  };

  const addedOffices = useMemo(() => {
    let officesRows = null;
    if (agentOffices.length === 0) {
      return officesRows;
    }

    const deleteButton = (officeObj) => {
      return (
        <div className="f-left" onClick={() => handleOnDelete(officeObj)}>
          <svg data-tippy="Remove" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 action-svg-delete">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </div>
      );
    };

    officesRows = agentOffices.map((office) => {
      return (
        <div key={office.id} className="roster-agent-office">
          <div className="roster-agent-office-name">{office.office_name}</div>
          {deleteButton(office)}
        </div>
      );
    });
    return officesRows;
  }, [agentOffices, handleOnDelete]);

  return (
    <>
      <div className="input-group m-t-15">
        <div className="input-wrapper flex2">
          <Dropdown title="Add Office" list={offices} handleOnChange={(newVal) => setOfficeSelected(newVal)} isLoading={officesFetching} isLoaded={!officesFetching} name="add-office" selected={officeSelected} />
        </div>
        <div className="input-wrapper">
          <button onClick={() => handleOnAddLocal(officeSelected)} className="btn-primary btn" disabled={isAdding ? true : false}>
            {isAdding ? "Adding..." : "Add"}
          </button>
        </div>
      </div>

      <div className="input-group m-t-15">
        <div className="input-wrapper">{addedOffices}</div>
      </div>
    </>
  );
};

AgentOffices.defaultProps = {
  offices: [],
  agentOffices: [],
  isAdding: false,
};

AgentOffices.propTypes = {
  /**
   * list of offices the broker has
   */
  offices: PropTypes.array.isRequired,
  /**
   * offices the agent belongs to
   */
  agentOffices: PropTypes.array.isRequired,
  /**
   * function to handle an office add
   */
  handleOnAdd: PropTypes.func.isRequired,
  /**
   * function to handle an office delete
   */
  handleOnDelete: PropTypes.func.isRequired,
  /**
   * used to change label and disable/enable add button
   */
  isAdding: PropTypes.bool.isRequired,
};

export default AgentOffices;
