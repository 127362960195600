import React, { useState } from "react";
import BackToolbar from "../controls/BackToolbar";
import { useSelector } from "react-redux";
import LenderInfo from "./LenderInfo";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import Loading from "../controls/Loading";
import { validateLender } from "../validation/LenderValidation";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

/**
 * Component for showing the preferred lender edit/add view.
 *
 * @component
 */
const PreferredLender = () => {
  const brUserId = useSelector((state) => state.auth.authUser.brUserId);
  const authToken = useSelector((state) => state.auth.authUser.token);
  const [lender, setLender] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [lenderFetched, setLenderFetched] = useState(false);
  const [lenderFetching, setLenderFetching] = useState(false);
  const [lenderFetchError, setLenderFetchError] = useState(false);
  const history = useHistory();

  if (!lenderFetched && !lenderFetching) {
    setLenderFetching(true);
    fetch(`${SITEBUILDER_URL}/api/agent/${brUserId}/preferred-lender`, fetchOptions(authToken, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred fetching the lender.", error);
          setLenderFetched(true);
          setLenderFetching(false);
          setLenderFetchError(true);
        }
      )
      .then((json) => {
        if (json.status) {
          setLenderDataState(json.data);
          setLenderFetched(true);
          setLenderFetching(false);
        } else {
          setLenderFetched(true);
          setLenderFetching(false);
          setLenderFetchError(true);
          console.log("error getting lender");
        }
      })
      .catch(function (e) {
        console.log(e);
        setLenderFetched(true);
        setLenderFetching(false);
        setLenderFetchError(true);
      });
  }

  const setLenderDataState = (lenderData) => {
    const loadedLender = {
      id: lenderData.id || 0,
      userID: lenderData.user_id || brUserId,
      firstName: lenderData.first_name || "",
      lastName: lenderData.last_name || "",
      email: { id: 0, email: lenderData.email || "", type: lenderData.email_type || "work" },
      phone: { id: 0, number: lenderData.phone || "", type: lenderData.phone_type || "cellular" },
      photoUrl: lenderData.photo_url || "",
      website: lenderData.website || "",
      title: lenderData.title || "",
      errors: {},
    };
    setLender(loadedLender);
  };

  const handleOnChange = (lender) => {
    setLender({ ...lender });
  };

  const handleOnSaveClick = () => {
    const errors = validateLender(lender);
    if (Object.keys(errors).length === 0) {
      setIsSaving(true);
      if (lender.id === 0) {
        saveLender(`${SITEBUILDER_URL}/api/agent/${brUserId}/preferred-lender`);
      } else {
        saveLender(`${SITEBUILDER_URL}/api/agent/${brUserId}/preferred-lender/${lender.id}`);
      }
    } else {
      toast.error("Data incomplete, please check the form for errors.");
      setLender({ ...lender, errors: errors });
    }
  };

  const saveLender = (url) => {
    let postOptions = fetchOptions(authToken, "POST");
    let data = new FormData();
    data.append("data", JSON.stringify(setPostLenderData(lender)));
    postOptions["body"] = data;

    return fetch(url, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred saving lender.", error);
          setIsSaving(false);
        }
      )
      .then((json) => {
        if (!json.status) {
          console.log("saving error");
          toast.error("Error saving lender." + json.msg);
        } else {
          toast.success("Lender saved.");
          setLender({ ...lender, id: json.data.id });
        }
        setIsSaving(false);
      })
      .catch(function (e) {
        toast.error("Error saving lender.");
        console.log(e);
        setIsSaving(false);
      });
  };

  const handleOnDeleteClick = () => {
    if (window.confirm("Are you sure?")) {
      console.log(lender);
      if (!lender.id || lender.id === 0) {
        toast.error("Data incomplete, please check the form for errors.");
      } else {
        deleteLender(`${SITEBUILDER_URL}/api/agent/${brUserId}/preferred-lender/${lender.id}`);
      }
    }
  };

  const deleteLender = (url) => {
    let deleteOptions = fetchOptions(authToken, "DELETE");

    return fetch(url, deleteOptions)
        .then(
            (response) => response.json(),
            (error) => {
              console.log("An error occurred deleting lender.", error);
              setIsSaving(false);
            }
        )
        .then((json) => {
          if (!json.status) {
            console.log("deleting error");
            toast.error("Error deleting lender." + json.msg);
          } else {
            toast.success("Lender deleted.");
            history.push("roster-list/");
          }
          setIsSaving(false);
        })
        .catch(function (e) {
          toast.error("Error saving lender.");
          console.log(e);
          setIsSaving(false);
        });
  };

  const setPostLenderData = (data) => {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email.email,
      email_type: data.email.type,
      phone: data.phone.number,
      phone_type: data.phone.type,
      website: data.website,
      title: data.title,
    };
  };

  const toolbarTitle = lender.id === 0 ? "Add Preferred Lender" : `Edit: ${lender.firstName} ${lender.lastName}`;

  if (lenderFetching) {
    return <Loading />;
  }

  if (lenderFetchError) {
    return <p className="m-l-15">Error loading agent!!!</p>;
  }

  if (lenderFetched) {
    return (
      <div className="main-page-content m-b-50">
        <BackToolbar backLink="/roster-list/" title={toolbarTitle} isSaving={isSaving} handleOnSaveClick={handleOnSaveClick}></BackToolbar>
        <div className="form-header">
          <img src="/images/icons/user.svg" alt="person icon" />
          Lender Info
        </div>
        <LenderInfo lender={lender} handleParentOnChange={handleOnChange} handleOnDeleteClick={handleOnDeleteClick}/>
      </div>
    );
  }
};

export default PreferredLender;
