import React from "react";
import { useDrop } from "react-dnd";

/**
 * Component for featured agents section
 *
 * @component
 */
const EmptyCard = () => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "AgentCard",
    drop: () => ({ name: "AgentCard" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  console.log("options", { canDrop, isOver });

  return (
    <div ref={drop} className="empty-card">
      <div className="dnd-icon">
        <img alt="drag and drop" src="/images/dnd_icon.png" />
      </div>
      <div className="label">
        Drag 'n' drop an <br />
        agent to be featured
      </div>
    </div>
  );
};

export default EmptyCard;
