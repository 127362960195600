import React from "react";
import AgentCounterStat from "./AgentCounterStat";
import PropTypes from "prop-types";

/**
 * Image list with drag and drop support, uses react-dnd
 *
 * @component
 * @example
 * return (
 *   <AgentCounterStats counterStats={[{},{},{}]} handleCounterStatsChange={() => {}} />
 * )
 */
const AgentCounterStats = ({ counterStats, handleCounterStatsChange }) => {
  const handleCounterStatChange = (index, stat) => {
    const updatedCounterStats = counterStats.map((counterStat, i) => {
      if (index === i) {
        return stat;
      }
      return counterStat;
    });
    handleCounterStatsChange(updatedCounterStats);
  };

  const settings = [
    { id: 1, prefix: "$", number: "Ex. 10,000,000", suffix: "Million", below: "Ex. In Total Transactions" },
    { id: 2, prefix: "$", number: "Ex. 4.9", suffix: "Stars", below: "Ex. Rating on Google" },
    { id: 3, prefix: "$", number: "Ex. 10.0000", suffix: "K", below: "Ex. Home Sold" },
  ];

  const stats = settings.map((stat, index) => {
    return <AgentCounterStat settings={stat} counterStat={counterStats[index]} handleCounterStatChange={handleCounterStatChange} key={index} />;
  });

  return (
    <div className="agent-stat-content">
      <h4 className="med-text black">Agent Counter Stats</h4>
      <div className="agent-stats m-t-20 m-b-30">{stats}</div>
    </div>
  );
};

AgentCounterStats.defaultProps = {
  counterStats: [{}, {}, {}],
};

AgentCounterStats.propTypes = {
  /**
   * array of counter stats values
   */
  counterStats: PropTypes.array.isRequired,
  /**
   * function to handle the change of values in all counter stats
   */
  handleCounterStatsChange: PropTypes.func.isRequired,
};

export default AgentCounterStats;
