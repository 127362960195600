import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "./common";
import * as types from "./actionTypes";

export const updateShowOnRosterAgent = (agentId, status, token) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATING_SHOW_ON_ROSTER_AGENT });
    return fetch(`${SITEBUILDER_URL}/api/agent/${agentId}/show-on-roster/${status}`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred updating agent.", error);
          dispatch({ type: types.UPDATE_SHOW_ON_ROSTER_AGENT_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.UPDATED_SHOW_ON_ROSTER_AGENT });
        } else {
          dispatch({ type: types.UPDATE_SHOW_ON_ROSTER_AGENT_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.UPDATE_SHOW_ON_ROSTER_AGENT_ERROR });
      });
  };
};

export const updateEnableProfilePage = (agentId, status, token) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATING_ENABLE_PROFILE_PAGE_AGENT });
    return fetch(`${SITEBUILDER_URL}/api/agent/${agentId}/enable-profile-page/${status}`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred updating agent.", error);
          dispatch({ type: types.UPDATE_ENABLE_PROFILE_PAGE_AGENT_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.UPDATED_ENABLE_PROFILE_PAGE_AGENT });
        } else {
          dispatch({ type: types.UPDATE_ENABLE_PROFILE_PAGE_AGENT_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.UPDATE_ENABLE_PROFILE_PAGE_AGENT_ERROR });
      });
  };
};

export const deleteRosterAgent = (agents, agentToDeleteId, token) => {
  return (dispatch) => {
    dispatch({ type: types.DELETING_ROSTER_AGENT });

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentToDeleteId}`, fetchOptions(token, "DELETE"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred deleting agent.", error);
          dispatch({ type: types.DELETE_ROSTER_AGENT_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          agents.items = agents.items.filter((agent) => {
            if (agent.user_id === agentToDeleteId) {
              return false;
            }
            return true;
          });
          agents.itemsTotal--;
          agents.itemsPageTotal--;
          dispatch({ type: types.DELETED_ROSTER_AGENT, agents: agents });
        } else {
          dispatch({ type: types.DELETE_ROSTER_AGENT_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.DELETE_ROSTER_AGENT_ERROR });
      });
  };
};

export const updateAgentFeatured = (agents, agentId, status, token) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATING_FEATURED_AGENT });
    if (status) {
      let featuredAgent;
      agents.items = agents.items.filter((agent) => {
        if (agent.user_id === agentId) {
          featuredAgent = agent;
          return false;
        }
        return true;
      });
      featuredAgent.is_featured = "1";
      agents.featuredItems.push(featuredAgent);
    } else {
      let regularAgent;
      agents.featuredItems = agents.featuredItems.filter((agent) => {
        if (agent.user_id === agentId) {
          regularAgent = agent;
          return false;
        }
        return true;
      });
      regularAgent.is_featured = "0";
      agents.items.push(regularAgent);
    }

    dispatch({ type: types.UPDATED_FEATURE_AGENT, agents: agents });

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentId}/is-featured/${status}`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred setting featured agent.", error);
          dispatch({ type: types.UPDATE_FEATURE_AGENT_ERROR });
        }
      )
      .then((json) => {
        if (!json.status) {
          dispatch({ type: types.UPDATE_FEATURE_AGENT_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.UPDATE_FEATURE_AGENT_ERROR });
      });
  };
};

export const loadRosterAgents = (brUserId, token, filters) => {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_ROSTER_AGENTS_FETCHING });

    return fetch(`${SITEBUILDER_URL}/api/${brUserId}/agents${filters}`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred getting agents.", error);
          dispatch({ type: types.LOAD_ROSTER_AGENTS_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.LOADED_ROSTER_AGENTS, agents: json.data });
        } else {
          dispatch({ type: types.LOAD_ROSTER_AGENTS_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.LOAD_ROSTER_AGENTS_ERROR });
      });
  };
};

export const loadRosterOffices = (brUserId, token) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const reduxOffices = currentState.roster.offices;
    dispatch({ type: types.LOAD_ROSTER_OFFICES_FETCHING });
    if (reduxOffices && reduxOffices.length > 0) {
      dispatch({ type: types.LOADED_ROSTER_OFFICES, offices: reduxOffices });
    } else {
      return fetch(`${SITEBUILDER_URL}/api/${brUserId}/offices/`, fetchOptions(token, "GET"))
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred getting offices.", error);
            dispatch({ type: types.LOAD_ROSTER_OFFICES_ERROR });
          }
        )
        .then((json) => {
          if (json.status) {
            dispatch({ type: types.LOADED_ROSTER_OFFICES, offices: json.data });
          } else {
            dispatch({ type: types.LOAD_ROSTER_OFFICES_ERROR });
          }
        })
        .catch(function (e) {
          console.log(e);
          dispatch({ type: types.LOAD_ROSTER_OFFICES_ERROR });
        });
    }
  };
};

export const loadRosterSettings = (brUserId, token) => {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_ROSTER_SETTINGS_FETCHING });

    return fetch(`${SITEBUILDER_URL}/api/${brUserId}/roster-settings`, fetchOptions(token, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred getting roster settings.", error);
          dispatch({ type: types.LOAD_ROSTER_SETTINGS_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.LOADED_ROSTER_SETTINGS, settings: json.data });
        } else {
          dispatch({ type: types.LOAD_ROSTER_SETTINGS_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.LOAD_ROSTER_SETTINGS_ERROR });
      });
  };
};

export const saveRosterSettings = (brUserId, token, settings) => {
  return (dispatch, getState) => {
    dispatch({ type: types.ROSTER_SETTINGS_SAVING });

    let postOptions = fetchOptions(token, "POST");
    let data = new FormData();
    data.append("data", JSON.stringify(settings));
    postOptions["body"] = data;

    return fetch(`${SITEBUILDER_URL}/api/${brUserId}/roster-settings`, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred saving roster settings.", error);
          dispatch({ type: types.SAVE_ROSTER_SETTINGS_ERROR });
        }
      )
      .then((json) => {
        if (json.status) {
          dispatch({ type: types.SAVED_ROSTER_SETTINGS, settings: json.data });
        } else {
          dispatch({ type: types.SAVE_ROSTER_SETTINGS_ERROR });
        }
      })
      .catch(function (e) {
        console.log(e);
        dispatch({ type: types.SAVE_ROSTER_SETTINGS_ERROR });
      });
  };
};
