import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * individual counter stat component
 *
 * @component
 */

const AgentCounterStat = ({ counterStat, settings, handleCounterStatChange }) => {
  const initIcon =
    typeof counterStat.icon === "undefined"
      ? '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock icons"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>'
      : counterStat.icon;
  const initPrefix = typeof counterStat.prefix === "undefined" ? "" : counterStat.prefix;
  const initNumber = typeof counterStat.number === "undefined" ? "" : counterStat.number;
  const initSuffix = typeof counterStat.suffix === "undefined" ? "" : counterStat.suffix;
  const initBelow = typeof counterStat.below === "undefined" ? "" : counterStat.below;

  const [icon, setIcon] = useState(initIcon);
  const [prefix, setPrefix] = useState(initPrefix);
  const [number, setNumber] = useState(initNumber);
  const [suffix, setSuffix] = useState(initSuffix);
  const [below, setBelow] = useState(initBelow);

  const ddID = `dropdownMenu${settings.id}`;
  const icons = [
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock icons"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-calendar icons"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home icons"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-dollar-sign icons"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star icons"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-award icons"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-up icons"><line x1="12" y1="19" x2="12" y2="5"></line><polyline points="5 12 12 5 19 12"></polyline></svg>`,
    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-down icons"><line x1="12" y1="5" x2="12" y2="19"></line><polyline points="19 12 12 19 5 12"></polyline></svg>`,
  ];

  const iconsLis = icons.map((row, index) => {
    return <li onClick={() => handleIconChange(index)} key={index} dangerouslySetInnerHTML={{ __html: row }}></li>;
  });

  const handleIconChange = (iconIndex) => {
    setIcon(icons[iconIndex]);
    handleOnChange("icon", icons[iconIndex]);
  };

  const handleOnChange = (property, value) => {
    const updateIndex = settings.id - 1;
    const updatedObj = { icon: icon, prefix: prefix, number: number, suffix: suffix, below: below };
    updatedObj[property] = value;
    handleCounterStatChange(updateIndex, updatedObj);
  };

  return (
    <div className="agent-stats-grid m-b-15" key={settings.id}>
      <div>
        <p className="med-text black">Stat {settings.id} Icon</p>
        <p>Above Stat</p>
        <div className="dropdown">
          <button className="btn btn-default dropdown-toggle icon-stat-button" type="button" id={ddID} data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
            <span className="selected-icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
            <span className="caret m-l-10"></span>
          </button>
          <ul className="dropdown-menu icon-stat-dropdown" aria-labelledby={ddID}>
            {iconsLis}
          </ul>
        </div>
      </div>
      <div>
        <p className="med-text black">Stat {settings.id}</p>
        <div className="stat-grid">
          <div>
            <p>Prefix</p>
            <input
              type="text"
              name="stats_prefix"
              onChange={(event) => {
                setPrefix(event.target.value);
                handleOnChange("prefix", event.target.value);
              }}
              placeholder={settings.prefix}
              className="agent-stats-input"
              value={prefix}
            />
          </div>
          <div>
            <p>Stat Number</p>
            <input
              type="text"
              name="stats_number"
              onChange={(event) => {
                setNumber(event.target.value);
                handleOnChange("number", event.target.value);
              }}
              placeholder={settings.number}
              className="agent-stats-input"
              value={number}
            />
          </div>
          <div>
            <p>Suffix</p>
            <input
              type="text"
              name="stats_suffix"
              onChange={(event) => {
                setSuffix(event.target.value);
                handleOnChange("suffix", event.target.value);
              }}
              placeholder={settings.suffix}
              className="agent-stats-input"
              value={suffix}
            />
          </div>
        </div>
      </div>
      <div>
        <p className="med-text black">Stat {settings.id} Text</p>
        <p>Below Stat</p>
        <input
          type="text"
          name="stats_below"
          onChange={(event) => {
            setBelow(event.target.value);
            handleOnChange("below", event.target.value);
          }}
          placeholder={settings.below}
          className="agent-stats-input"
          value={below}
        />
      </div>
    </div>
  );
};

AgentCounterStat.propTypes = {
  /**
   * object for a counterStat
   * {
   * "icon": "<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clock icons"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>",
   * "prefix": "$",
   * "number": "10",
   * "suffix": "Million",
   * "below": "Total Transactions"
   * }
   */
  counterStat: PropTypes.object.isRequired,
  /**
   * object of placeholders on form
   * {
   * "id": 1,
   * "prefix": "$",
   * "number": "Ex. 10,000,000",
   * "suffix": "Million",
   * "below": "Ex. In Total Transactions"
   * }
   */
  settings: PropTypes.object.isRequired,
  /**
   * function to handle the change and update props
   */
  handleCounterStatChange: PropTypes.func.isRequired,
};

export default AgentCounterStat;
