import React from "react";
import "./css/Loading.css";

/**
 * Loading gif
 *
 * @component
 */
const Loading = () => {
  return (
    <div className="loadingContainer">
      <img alt="Loading..." src="https://cdn1.brivityidx.com/assets/images/Spinner-1s-200px-2.svg" />
    </div>
  );
};

export default Loading;
