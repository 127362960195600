import React, { useState } from "react";
import RosterToolbar from "./RosterToolbar";
import AgentCard from "../agent/AgentCard";
import { useSelector, useDispatch } from "react-redux";
import { loadRosterAgents, loadRosterOffices, deleteRosterAgent, loadRosterSettings, saveRosterSettings, updateAgentFeatured } from "../../store/actions/rosterActions";
import Loading from "../controls/Loading";
import PaginationHeader from "../controls/PaginationHeader";
import Pagination from "../controls/Pagination";
import { toast } from "react-toastify";
import FeaturedAgents from "./FeaturedAgents";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";

/**
 * Component for showing the roster list.
 *
 * @component
 */
const RosterList = () => {
  const [agentFilters, setAgentFilters] = useState({
    office: "all",
  });
  const [reloadAgents, setReloadAgents] = useState(false);
  //auth
  const brUserId = useSelector((state) => state.auth.authUser.brUserId);
  const authToken = useSelector((state) => state.auth.authUser.token);
  //agent list
  const agentsLoaded = useSelector((state) => state.roster.agentsLoaded);
  const agentsLoadError = useSelector((state) => state.roster.agentsLoadError);
  const agentsFetching = useSelector((state) => state.roster.agentsFetching);
  const list = useSelector((state) => state.roster.agents);
  //single agent delete
  const agentDeleteError = useSelector((state) => state.roster.agentDeleteError);
  const agentDeleting = useSelector((state) => state.roster.agentDeleting);
  //set featured
  const agentUpdateFeaturedError = useSelector((state) => state.roster.agentUpdateFeaturedError);
  const agentUpdatingFeatured = useSelector((state) => state.roster.agentUpdatingFeatured);

  //roster settings
  const rosterSettingsFetching = useSelector((state) => state.roster.rosterSettingsFetching);
  const rosterSettingsLoaded = useSelector((state) => state.roster.rosterSettingsLoaded);
  const rosterSettingsSaving = useSelector((state) => state.roster.rosterSettingsSaving);
  const rosterSettings = useSelector((state) => state.roster.settings);
  //offices list
  const officesFetching = useSelector((state) => state.roster.officesFetching);
  const offices = useSelector((state) => state.roster.offices);
  //dispatch
  const dispatch = useDispatch();

  const isTouchDevice = () => {
    if ("ontouchstart" in window) {
      return true;
    }
    return false;
  };
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

  const handleUpdateFeatured = (userID, status) => {
    if (!agentUpdatingFeatured) {
      dispatch(updateAgentFeatured(list, userID, status, authToken));
    }
    if (agentUpdateFeaturedError) {
      toast.error("Error updating featured agent.");
    }
  };

  //delete logic
  const handleDeleteAgent = (userID) => {
    if (!agentDeleting) {
      var r = window.confirm("Are you sure?");
      if (r === true) {
        dispatch(deleteRosterAgent(list, userID, authToken));
      }
    }
  };
  if (agentDeleteError) {
    toast.error("Error deleting agent.");
  }
  //end delete logic

  //office filter logic
  const handleOfficeChange = (office) => {
    const newFilters = { ...agentFilters, office: office };
    setAgentFilters(newFilters);
    getAgents({ ...newFilters, ...rosterSettings });
  };

  const handleRosterSettingsSave = (orderValue) => {
    const settingsVal = orderValue.split("|");
    dispatch(
      saveRosterSettings(brUserId, authToken, {
        sort_column: settingsVal[0],
        sort_order: settingsVal[1],
      })
    );
  };

  const getAgents = (filtersObj) => {
    let queryString = "";
    const entries = Object.entries(filtersObj);
    for (let i = 0; i < entries.length; i++) {
      if (i === 0) {
        queryString += "?";
      } else {
        queryString += "&";
      }
      queryString += entries[i][0] + "=" + entries[i][1];
    }
    queryString += queryString.includes("?") ? "&ignoreShowOnRoster=true" : "?ignoreShowOnRoster=true";
    dispatch(loadRosterAgents(brUserId, authToken, queryString));
  };

  //toolbar

  const handleSearch = (term) => {
    getAgents({ ...agentFilters, ...rosterSettings, page: 1, search: term });
  };

  const toolBar = (
    <RosterToolbar
      offices={offices}
      selected={agentFilters["office"]}
      isOfficeLoading={officesFetching}
      isSettingsSaving={rosterSettingsSaving}
      handleOfficeChange={handleOfficeChange}
      handleRosterSettingsSave={handleRosterSettingsSave}
      order={typeof rosterSettings.sort_column !== "undefined" && typeof rosterSettings.sort_order !== "undefined" ? `${rosterSettings.sort_column}|${rosterSettings.sort_order}` : "random|asc"}
      handleSearch={handleSearch}
    />
  );

  //end toolbar

  //end office filter logic

  //pagination
  const handlePageChange = (toPage) => {
    getAgents({ ...agentFilters, ...rosterSettings, page: toPage });
  };
  //end pagination

  //agents roster logic
  if (agentsLoadError) {
    return <p className="m-l-15">Error loading agents!!!</p>;
  }

  if (rosterSettingsSaving && !reloadAgents) {
    setReloadAgents(true);
  }

  if (!rosterSettingsSaving && reloadAgents) {
    setReloadAgents(false);
    getAgents({ ...rosterSettings, ...agentFilters });
  }

  if (rosterSettingsFetching) {
    return (
      <div className="main-page-content m-b-50">
        <Loading />
      </div>
    );
  }

  if (rosterSettingsLoaded && !agentsLoaded) {
    if (!agentsFetching) {
      getAgents(rosterSettings);
    }
    return (
      <div className="main-page-content m-b-50">
        {toolBar}
        <Loading />
      </div>
    );
  }

  if (agentsLoaded) {
    const pagination = <Pagination handlePageChange={handlePageChange} totalResults={list.itemsTotal} perPageResults={list.itemsPerPage} page={list.pagesCurrent} pageResults={list.itemsPageTotal} />;
    return (
      <div className="main-page-content m-b-50">
        {toolBar}
        <div className="roster-content">
          <PaginationHeader totalResults={list.itemsTotal} pageResults={list.itemsPageTotal} perPageResults={list.itemsPerPage} page={list.pagesCurrent} label="agents" />
          <DndProvider backend={backendForDND}>
            <FeaturedAgents agents={list.featuredItems.length > 0 ? list.featuredItems : []} handleDeleteAgent={handleDeleteAgent} handleUpdateFeatured={handleUpdateFeatured} />
            {pagination}
            <div id="filter-agent-list" className="agent-roster-wrapper ui-sortable m-t-10">
              {list.items.length > 0 ? (
                list.items.map((agent) => {
                  return <AgentCard handleDeleteAgent={handleDeleteAgent} handleUpdateFeatured={handleUpdateFeatured} agent={agent} key={agent.user_id} />;
                })
              ) : (
                <p>No agents found.</p>
              )}
            </div>
          </DndProvider>
          {list.items.length > 0 && pagination}
        </div>
      </div>
    );
  } else {
    dispatch(loadRosterOffices(brUserId, authToken));
    dispatch(loadRosterSettings(brUserId, authToken));
    return null;
  }
  //end agents roster logic
};

export default RosterList;
