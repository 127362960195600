import React from "react";
import PropTypes from "prop-types";

const AgentTestimonial = ({ id, clientName, title, body, handleOnChange, handleOnDelete, errors }) => {
  const testimonialObj = { id, client_name: clientName, title, body };

  const handleOnValChange = (e) => {
    const tempObj = testimonialObj;
    const targetID = e.target.id;
    tempObj[targetID.replace(`-${id}`, "")] = e.target.value;
    handleOnChange(tempObj);
  };

  return (
    <div className="testimonial m-b-15">
      <div className="info-group">
        <div className="header">
          <div className="header-label">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-star">
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
            </svg>
            <span>Testimonial</span>
          </div>
          <div className="header-actions">
            <div data-tippy="Remove Testimonial from Site" className="header-actions">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" onClick={() => handleOnDelete(id)} className="feather feather-trash-2 action-svg-delete">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </div>
          </div>
        </div>
        <div className="data-collection">
          <div className="input-group">
            <div className="input-wrapper">
              <label htmlFor="testimonial-client_name">Name*</label>
              <input type="text" id={`client_name-${id}`} placeholder="Enter Client Name" onChange={handleOnValChange} value={clientName} />
              {errors.client_name && <div className="error">{errors.client_name}</div>}
            </div>
          </div>
          <div className="input-group m-t-15 w-50">
            <div className="input-wrapper">
              <label htmlFor="title">Title*</label>
              <input type="text" id={`title-${id}`} placeholder="We Are Very Happy" onChange={handleOnValChange} value={title} />
              {errors.title && <div className="error">{errors.title}</div>}
            </div>
          </div>
          <div className="input-group m-t-15">
            <div className="input-wrapper">
              <label>Testimonial Text*</label>
              <textarea
                id={`body-${id}`}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
                onChange={handleOnValChange}
                value={body}
              />
              {errors.body && <div className="error">{errors.body}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentTestimonial.propTypes = {
  /**
   * db int id or new testimonial id
   */
  id: PropTypes.string.isRequired,
  /**
   * name
   */
  clientName: PropTypes.string.isRequired,
  /**
   * title for testimonial
   */
  title: PropTypes.string.isRequired,
  /**
   * text for testimonial
   */
  body: PropTypes.string.isRequired,
  /**
   * function to handle a testimonial delete
   */
  handleOnDelete: PropTypes.func.isRequired,
};

export default AgentTestimonial;
