import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useDrag } from "react-dnd";

/**
 * Component that displays a card for with agent info
 *
 * @component
 */
const AgentCard = ({ agent, handleDeleteAgent, handleUpdateFeatured }) => {
  const style = {
    backgroundImage: "url(" + agent.image + ")",
    backgroundSize: "cover",
    opacity: "1",
  };

  const [{ isDragging }, drag] = useDrag({
    item: { name: agent.user_id, type: "AgentCard" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && dropResult.name === "AgentCard") {
        handleUpdateFeatured(item.name, true);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={agent.is_featured === "1" ? null : drag} className="agent-card" style={{ opacity }}>
      <div className="agent-content">
        <div className="agent-profile-image">
          {agent.is_featured === "1" ? (
            <div className="remove-button" onClick={() => handleUpdateFeatured(agent.user_id, false)}>
              x
            </div>
          ) : null}
          <Link to={"/agent/" + agent.user_id}>
            <div className="round-agent-pic" style={style}></div>
          </Link>
        </div>
        <div className="agent-name">
          <h4>
            {agent.first_name} {agent.last_name}
          </h4>
        </div>
        <div className="agent-title">
          <p>{agent.title}&nbsp;</p>
        </div>
      </div>
      <div className="agent-actions-wrapper">
        <Link to={"/agent/" + agent.user_id} className="listing-action">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit-3"
          >
            <path d="M12 20h9"></path>
            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
          </svg>
          <span className="m-l-5">Edit</span>
        </Link>
        <div className="show-on-site">
          <svg
            onClick={() => handleDeleteAgent(agent.user_id)}
            className="delete action"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
          </svg>
        </div>
      </div>
    </div>
  );
};

AgentCard.propTypes = {
  /**
   * object with agent information
   * {"user_id":"123123",
   * "first_name":"John",
   * "last_name":"Doe",
   * "image":"https://urltoimage.com/no_user_image.png",
   * "show_on_roster":"1",
   * "title":"Best Agent"}
   */
  agent: PropTypes.object.isRequired,
  handleDeleteAgent: PropTypes.func.isRequired,
  handleUpdateFeatured: PropTypes.func,
};

export default AgentCard;
