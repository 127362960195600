import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * Bootstrap modal wrapper that takes the body content as children
 *
 * @component
 */
const ModalDialog = (props) => {
  const { title, handleClose, show } = props;
  return (
    <div className="static-modal">
      <Modal animation={false} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </div>
  );
};

ModalDialog.propTypes = {
  /**
   * title of the modal
   */
  title: PropTypes.string.isRequired,
  /**
   * function to handle the close of the modal
   */
  handleClose: PropTypes.func.isRequired,
  /**
   * show or hide the modal
   */
  show: PropTypes.bool.isRequired,
  /**
   * body of the modal
   */
  children: PropTypes.element.isRequired,
};

export default ModalDialog;
