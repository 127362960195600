import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "./common";
import * as types from "./actionTypes";

export const fetchCountries = (token) => {
  return (dispatch, getState) => {
    const currentState = getState();
    const reduxCountries = currentState.address.countries;
    dispatch({ type: types.FETCHING_COUNTRIES });
    if (reduxCountries && reduxCountries.length > 0) {
      dispatch({ type: types.FETCHED_COUNTRIES, countries: reduxCountries });
    } else {
      //dispatch({type: 'FETCHING_COUNTRIES'});
      return fetch(`${SITEBUILDER_URL}/api/countries`, fetchOptions(token, "GET"))
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred fetching countries.", error);
            dispatch({ type: types.FETCH_COUNTRIES_ERROR });
          }
        )
        .then((json) => {
          if (json.status) {
            const countries = json.data.map((country) => {
              if (country.value === "1") {
                country.checked = true;
              }
              return country;
            });
            dispatch({ type: types.FETCHED_COUNTRIES, countries: countries });
          } else {
            dispatch({ type: types.FETCH_COUNTRIES_ERROR });
          }
        })
        .catch(function (e) {
          console.log(e);
          dispatch({ type: types.FETCH_COUNTRIES_ERROR });
        });
    }
  };
};

export const fetchStates = (countryIds, token) => {
  const allStatesCached = (ids, reduxStates) => {
    for (let i = 0; i < ids.length; i++) {
      if (!reduxStates[ids[i]]) {
        return false;
      }
    }
    return true;
  };

  const mergeReduxStates = (ids, reduxStates, results) => {
    let toAdd = [];
    for (let i = 0; i < ids.length; i++) {
      if (!reduxStates[ids[i]]) {
        toAdd.push(ids[i]);
      }
    }
    for (let i = 0; i < toAdd.length; i++) {
      reduxStates = { ...reduxStates, [toAdd[i]]: results[toAdd[i]] };
    }
    return reduxStates;
  };

  return (dispatch, getState) => {
    const currentState = getState();
    const reduxStates = currentState.address.states;
    dispatch({ type: types.FETCHING_STATES });
    if (allStatesCached(countryIds, reduxStates)) {
      dispatch({ type: types.FETCHED_STATES, states: reduxStates });
    } else {
      //dispatch({type: 'FETCHING_STATES'});
      return fetch(`${SITEBUILDER_URL}/api/country/${countryIds.join("|")}/states`, fetchOptions(token, "GET"))
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred fetching states.", error);
            dispatch({ type: types.FETCH_STATES_ERROR });
          }
        )
        .then((json) => {
          if (json.status) {
            let merged = mergeReduxStates(countryIds, reduxStates, json.data);
            dispatch({ type: types.FETCHED_STATES, states: merged });
          } else {
            dispatch({ type: types.FETCH_STATES_ERROR });
          }
        })
        .catch(function (e) {
          console.log(e);
          dispatch({ type: types.FETCH_STATES_ERROR });
        });
    }
  };
};

export const fetchCities = (stateIds, token) => {
  const allCitiesCached = (ids, reduxCities) => {
    for (let i = 0; i < ids.length; i++) {
      if (!reduxCities[ids[i]]) {
        return false;
      }
    }
    return true;
  };

  const mergeReduxCities = (ids, reduxCities, results) => {
    let toAdd = [];
    for (let i = 0; i < ids.length; i++) {
      if (!reduxCities[ids[i]]) {
        toAdd.push(ids[i]);
      }
    }
    for (let i = 0; i < toAdd.length; i++) {
      reduxCities = { ...reduxCities, [toAdd[i]]: results[toAdd[i]] };
    }
    return reduxCities;
  };

  return (dispatch, getState) => {
    const currentState = getState();
    const reduxCities = currentState.address.cities;
    dispatch({ type: types.FETCHING_CITIES });
    if (allCitiesCached(stateIds, reduxCities)) {
      dispatch({ type: types.FETCHED_CITIES, cities: reduxCities });
    } else {
      return fetch(`${SITEBUILDER_URL}/api/state/${stateIds.join("|")}/cities`, fetchOptions(token, "GET"))
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred fetching cities.", error);
            dispatch({ type: types.FETCH_CITIES_ERROR });
          }
        )
        .then((json) => {
          if (json.status) {
            let merged = mergeReduxCities(stateIds, reduxCities, json.data);
            dispatch({ type: types.FETCHED_CITIES, cities: merged });
          } else {
            dispatch({ type: types.FETCH_CITIES_ERROR });
          }
        })
        .catch(function (e) {
          console.log(e);
          dispatch({ type: types.FETCH_CITIES_ERROR });
        });
    }
  };
};
