import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isTokenValid } from "../../store/actions/authActions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isFetching, setIsFetching] = useState(true);
  const authToken = useSelector((state) => state.auth.authUser.token);
  const authError = useSelector((state) => state.auth.authError);
  const authIsFetching = useSelector((state) => state.auth.authFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isTokenValid(authToken));
    setIsFetching(false);
  }, [authToken, dispatch, setIsFetching]);

  if (authIsFetching || isFetching) {
    return null;
  } else if (authError) {
    window.location.assign(process.env.REACT_APP_SITEBUILDER_URL);
    return null;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default PrivateRoute;
