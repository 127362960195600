import React, { useState, useEffect } from "react";
import AgentCard from "../agent/AgentCard";
import PropTypes from "prop-types";
import EmptyCard from "./EmptyCard";

/**
 * Component for featured agents section
 *
 * @component
 */
const FeaturedAgents = ({ agents, handleDeleteAgent, handleUpdateFeatured }) => {
  const [showFeatured, setShowFeatured] = useState(true);
  const [featuredAgents, setFeaturedAgents] = useState([]);
  useEffect(() => {
    let spotsLeft = 4;
    let toDisplay = [];
    if (agents.length > 0) {
      toDisplay = agents.map((agent) => {
        spotsLeft--;
        return <AgentCard handleDeleteAgent={handleDeleteAgent} handleUpdateFeatured={handleUpdateFeatured} agent={agent} key={agent.user_id} />;
      });
    }

    for (let i = 0; i < spotsLeft; i++) {
      toDisplay.push(<EmptyCard key={`${i}-key`} />);
    }
    setFeaturedAgents(toDisplay);
  }, [agents, handleDeleteAgent, handleUpdateFeatured]);

  const toggleFeaturedContainer = () => {
    setShowFeatured(!showFeatured);
  };

  return (
    <React.Fragment>
      <div className="m-t-10">
        <div className="featured-button" onClick={toggleFeaturedContainer}>
          <div>Featured Agents</div>
          <div>
            <svg
              className={showFeatured ? "caret-up" : "caret-down"}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
      </div>
      {showFeatured ? (
        <div className="featured-container">
          <div className="header m-b-20">Drag and drop the agents you would like featured</div>
          {featuredAgents}
        </div>
      ) : null}
    </React.Fragment>
  );
};

FeaturedAgents.propTypes = {
  agents: PropTypes.array.isRequired,
  handleDeleteAgent: PropTypes.func.isRequired,
  handleUpdateFeatured: PropTypes.func.isRequired,
};

export default FeaturedAgents;
