import React from "react";
import PropTypes from "prop-types";

/**
 * container of tab body, should be included into a tabs component
 *
 * @component
 */
const TabPane = ({ id, activeTab, children }) => {
  return (
    <div className={activeTab === id ? "tab-pane bg-white p-30 open" : "tab-pane bg-white p-30"} id={id}>
      {children}
    </div>
  );
};

TabPane.propTypes = {
  /**
   * id of the tab pane
   */
  id: PropTypes.string.isRequired,
  /**
   * id of the active tab to display
   */
  activeTab: PropTypes.string.isRequired,
  /**
   * content to display
   */
  children: PropTypes.element.isRequired,
};

export default TabPane;
