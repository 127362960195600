export const SITEBUILDER_URL = process.env.REACT_APP_SITEBUILDER_URL;

export const fetchOptions = (token,verb) => {
    const options = {
        headers: {
          'x-api-key': token
        },
        cache: 'no-cache',
        method: verb,
        mode: 'cors',
      }
    return options
}