import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case types.LOGIN_IS_FETCHING:
      return {
        ...state,
        authFetching: true,
        authIsLoggedIn: false,
        authError: false,
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        authError: true,
        authFetching: false,
        authIsLoggedIn: false,
      };

    case types.LOGIN_TOKEN_IS_VALID:
      return {
        ...state,
        authError: false,
        authFetching: false,
        authIsLoggedIn: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authError: null,
        authFetching: false,
        authIsLoggedIn: true,
        authUser: action.user,
      };

    default:
      return state;
  }
};

export default authReducer;
