import React, { useState } from "react";
import AgentEditToolbar from "./AgentEditToolbar";
import Tabs from "../controls/Tabs";
import TabPane from "../controls/TabPane";
import AgentAbout from "./AgentAbout";
import AgentMedia from "./AgentMedia";
import AgentTestimonials from "./AgentTestimonials"; //will be added later
import AgentCounterStats from "./AgentCounterStats";
import AgentLinksContainer from "./AgentLinksContainer";

import { validateAgent, validateOtherLinkAdd } from "../validation/AgentValidation";
import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../controls/Loading";
import update from "immutability-helper";
import { toast } from "react-toastify";
import { updateShowOnRosterAgent, updateEnableProfilePage } from "../../store/actions/rosterActions";
/**
 * container for all agent edit components and handles most logic for get, save, delete, also keeps the state of agent object
 *
 * @component
 */
const AgentEdit = (props) => {
  const authToken = useSelector((state) => state.auth.authUser.token);
  const brUserId = useSelector((state) => state.auth.authUser.brUserId);
  const siteUrl = useSelector((state) => state.auth.authUser.site_url);

  let tabsInfo = [
    { id: 1, title: "About", dataTab: "agent-about", icon: "/images/icons/user.svg" },
    { id: 2, title: "Media", dataTab: "agent-media", icon: "/images/icons/image.svg" },
    { id: 3, title: "Testimonials", dataTab: "agent-testimonials", icon: "/images/icons/message-square.svg" },
    { id: 4, title: "Agent Counter Stats", dataTab: "agent-count-stats", icon: "/images/icons/bar-chart-2.svg" },
    { id: 5, title: "Links", dataTab: "agent-social", icon: "/images/icons/share-2.svg" },
  ];

  const [activeTab, setActiveTab] = useState("agent-about");
  const [agent, setAgent] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [agentFetched, setAgentFetched] = useState(false);
  const [agentFetching, setAgentFetching] = useState(false);
  const [agentFetchError, setAgentFetchError] = useState(false);
  const agentID = props.match.params.id;
  const agentUpdatingShowOnRoster = useSelector((state) => state.roster.agentUpdatingShowOnRoster);
  const agentUpdatingShowOnRosterError = useSelector((state) => state.roster.agentUpdatingShowOnRosterError);
  const agentUpdatingEnableProfilePage = useSelector((state) => state.roster.agentUpdatingEnableProfilePage);
  const agentUpdatingEnableProfilePageError = useSelector((state) => state.roster.agentUpdatingEnableProfilePageError);

  //dispatch
  const dispatch = useDispatch();

  const isEdit = true;

  if (!agentFetched && !agentFetching) {
    setAgentFetching(true);
    fetch(`${SITEBUILDER_URL}/api/agent/${agentID}`, fetchOptions(authToken, "GET"))
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred fetching the agent.", error);
          setAgentFetched(true);
          setAgentFetching(false);
          setAgentFetchError(true);
        }
      )
      .then((json) => {
        if (json.status) {
          setAgentDataState(json.data);
          setAgentFetched(true);
          setAgentFetching(false);
        } else {
          setAgentFetched(true);
          setAgentFetching(false);
          setAgentFetchError(true);
          console.log("error getting agent");
        }
      })
      .catch(function (e) {
        console.log(e);
        setAgentFetched(true);
        setAgentFetching(false);
        setAgentFetchError(true);
      });
  }

  const handleTabChange = (id) => {
    setActiveTab(id);
  };

  const handleAboutChanges = (about, phones, emails, addresses, offices) => {
    setAgent({ ...agent, about: { ...about }, phones: phones, emails: emails, addresses: addresses, offices: offices });
  };

  const handleCounterStatsChanges = (counterStats) => {
    setAgent({ ...agent, counterStats: counterStats });
  };

  const handleSocialChanges = (social) => {
    setAgent({ ...agent, social: { ...social } });
  };

  const handleReviewChanges = (reviewLinks) => {
    setAgent({ ...agent, reviewLinks: [...reviewLinks] });
  };

  const handleTestimonialChanges = (testimonials) => {
    setAgent({ ...agent, testimonials: testimonials });
  };

  const handleOtherAdd = (value, title, url, icon) => {
    const errors = validateOtherLinkAdd(title, url);
    if (Object.keys(errors).length === 0) {
      let postOptions = fetchOptions(authToken, "POST");
      let data = new FormData();
      data.append("data", JSON.stringify({ userIconLinkID: value, title: title, url: url, category: "other" }));
      postOptions["body"] = data;

      return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/link`, postOptions)
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred saving link.", error);
            setIsSaving(false);
          }
        )
        .then((json) => {
          if (!json.status) {
            toast.error("Error saving link." + json.msg);
          } else {
            let tempOther = agent.otherLinks;
            tempOther.push({ id: json.data, title: title, url: url, icon: icon });
            setAgent({ ...agent, otherLinks: tempOther });
          }
          setIsSaving(false);
        })
        .catch(function (e) {
          toast.error("Error saving link.");
          console.log(e);
          setIsSaving(false);
        });
    } else {
      toast.error("Data incomplete, please check the form for errors.");
      setAgent({ ...agent, errors: { other: errors } });
    }
  };

  const handleOnSaveClick = () => {
    const errors = validateAgent(agent);
    if (Object.keys(errors).length === 0) {
      setIsSaving(true);
      let postOptions = fetchOptions(authToken, "POST");
      let data = new FormData();
      data.append("data", JSON.stringify(setEditAgentData(agent)));
      postOptions["body"] = data;

      return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}`, postOptions)
        .then(
          (response) => response.json(),
          (error) => {
            console.log("An error occurred updatingAgent.", error);
            setIsSaving(false);
          }
        )
        .then((json) => {
          if (!json.status) {
            console.log("updating error");
            toast.error("Error saving agent." + json.msg);
          } else {
            toast.success("Agent saved.");
            setAgentDataState(json.data);
          }
          setIsSaving(false);
        })
        .catch(function (e) {
          toast.error("Error saving agent.");
          console.log(e);
          setIsSaving(false);
        });
    } else {
      toast.error("Data incomplete, please check the form for errors.");
      setAgent({ ...agent, errors: errors });
    }
  };

  const handleImageUpload = (uploadedImage) => {
    let tempImages = agent.media;
    tempImages.push(uploadedImage);
    setAgent({ ...agent, media: tempImages });
  };

  const handleOtherDelete = (linkId) => {
    let postOptions = fetchOptions(authToken, "DELETE");

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/link/${linkId}`, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred deleting link.", error);
        }
      )
      .then((json) => {
        if (json.status) {
          const tempOther = agent.otherLinks.filter((other) => {
            if (other.id === linkId) {
              return false;
            }
            return true;
          });
          setAgent({ ...agent, otherLinks: tempOther });
        } else {
          console.log("deleting error");
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const handleImageDelete = (imageId) => {
    let postOptions = fetchOptions(authToken, "DELETE");

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/image/${imageId}`, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred fetching countries.", error);
          setIsSaving(false);
        }
      )
      .then((json) => {
        if (json.status) {
          const tempImages = agent.media.filter((image) => {
            if (image.id === imageId) {
              return false;
            }
            return true;
          });
          setAgent({ ...agent, media: tempImages });
        } else {
          console.log("saving error");
        }
        setIsSaving(false);
      })
      .catch(function (e) {
        console.log(e);
        setIsSaving(false);
      });
  };

  const handleImageReorder = (dragIndex, hoverIndex) => {
    // Get the dragged element
    const draggedImage = agent.media[dragIndex];
    /*
          - copy the dragged image before hovered element (i.e., [hoverIndex, 0, draggedImage])
          - remove the previous reference of dragged element (i.e., [dragIndex, 1])
          - here we are using this update helper method from immutability-helper package
        */
    const tempImages = update(agent.media, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedImage],
      ],
    });
    setAgent({ ...agent, media: tempImages });
  };

  const handleImageDropSave = () => {
    let postOptions = fetchOptions(authToken, "POST");
    let data = new FormData();
    data.append("images", JSON.stringify(agent.media));
    postOptions["body"] = data;

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/images/positions`, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          console.log("An error occurred saving images positions.", error);
        }
      )
      .then((json) => {
        if (!json.status) {
          alert("Saving error on images positions");
        }
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const setAgentDataState = (agentData) => {
    let mediaData;
    let reviewLinks;
    let otherLinks;

    if (typeof agentData.media === "undefined") {
      mediaData = agent.media ? agent.media : [];
    } else {
      mediaData = agentData.media;
    }

    if (typeof agentData.links.review === "undefined") {
      reviewLinks = [];
    } else {
      reviewLinks = agentData.links.review;
    }

    if (typeof agentData.links.other === "undefined") {
      otherLinks = [];
    } else {
      otherLinks = agentData.links.other;
    }

    const loadedAgent = {
      id: agentData.user_id,
      about: {
        firstName: agentData.f_name,
        lastName: agentData.l_name,
        mainPhoto: agentData.image_url,
        title: agentData.title,
        keywords: agentData.keywords,
        biography: agentData.bio,
        showBio: agentData.show_bio === "1" ? true : false,
        videoUrl: agentData.external_url,
        favorites: agentData.favorite_things,
        qualifications: agentData.qualifications,
        agentMlsID: agentData.agent_id,
        website: agentData.website,
        licenseID: agentData.agent_license_id,
        subdomainID: agentData.subdomainID !== "" ? parseInt(agentData.subdomainID) : 0,
        subdomain: agentData.subdomain,
        brokerageDomain: agentData.domain,
        showOnRoster: agentData.show_on_roster === "1",
      },
      phones: agentData.phones.map( phone => ({...phone, number: phone.number.replace(/[^0-9]/g, '')})),
      emails: agentData.emails,
      addresses: agentData.addresses,
      offices: agentData.offices,
      media: mediaData,
      testimonials: typeof agentData.testimonials !== "undefined" ? agentData.testimonials : [],
      social: agentData.social,
      links: agentData.links,
      reviewLinks: reviewLinks,
      otherLinks: otherLinks,
      counterStats: agentData.counterStats,
      errors: {},
    };
    setAgent(loadedAgent);
  };

  const setEditAgentData = (agentData) => {
    let postData = {
      brUserID: brUserId,
      f_name: agentData.about.firstName,
      l_name: agentData.about.lastName,
      show_bio: agentData.about.showBio ? "1" : "0",
      external_url: agentData.about.videoUrl,
      title: agentData.about.title,
      keywords: agentData.about.keywords,
      biography: agentData.about.biography,
      favorites: agentData.about.favorites,
      qualifications: agentData.about.qualifications,
      agent_id: agentData.about.agentMlsID,
      website: agentData.about.website,
      agent_license_id: agentData.about.licenseID,
      emails: agentData.emails,
      phones: agentData.phones,
      addresses: agentData.addresses,
      testimonials: agentData.testimonials,
      social: agentData.social,
      counterStats: agentData.counterStats,
      allow_agent_sync: 0,
      domain: agentData.about.brokerageDomain,
      subdomain: agentData.about.subdomain,
      subdomainID: agentData.about.subdomainID,
      links: { other: agentData.otherLinks, review: agentData.reviewLinks },
    };
    return postData;
  };
  //show on roster
  const handleShowOnRoster = (about, userID, status) => {
    if (!agentUpdatingShowOnRoster) {
      dispatch(updateShowOnRosterAgent(userID, status, authToken));

      setTimeout(() => {
        setAgentFetched(false);
        setAgentFetching(false);
      }, 2000);
    }
  };

  const handleEnableProfilePage = (about, userID, status) => {
    if (!agentUpdatingEnableProfilePage) {
      dispatch(updateEnableProfilePage(userID, status, authToken));

      setTimeout(() => {
        setAgentFetched(false);
        setAgentFetching(false);
      }, 2000);
    }
  };

  if (agentUpdatingShowOnRosterError || agentUpdatingEnableProfilePageError) {
    toast.error("Error updating agent.");
  }
  //end show on roster

  if (agentFetching) {
    return <Loading />;
  }

  if (agentFetchError) {
    return <p className="m-l-15">Error loading agent!!!</p>;
  }

  const setViewUrl = (url, fName, lName, agentID) => {
    return siteUrl !== "" ? `${url}/agent-profile/${encodeURI(fName)}-${encodeURI(lName)}-${agentID}` : "";
  };

  if (agentFetched) {
    return (
      <div className="main-page-content m-b-50">
        <AgentEditToolbar handleOnSaveClick={handleOnSaveClick} name={agent.about.firstName + " " + agent.about.lastName} isEdit={isEdit} isSaving={isSaving} viewUrl={setViewUrl(siteUrl, agent.about.firstName, agent.about.lastName, agentID)} />
        <Tabs list={tabsInfo} handleChange={handleTabChange} activeTab={activeTab}>
          <TabPane id="agent-about" activeTab={activeTab}>
            <AgentAbout isEdit={isEdit} agent={agent} handleAboutChange={handleAboutChanges} handleShowOnRoster={handleShowOnRoster} handleEnableProfilePage={handleEnableProfilePage}/>
          </TabPane>
          {isEdit && (
            <TabPane id="agent-media" activeTab={activeTab}>
              <AgentMedia agentID={agentID} media={agent.media} handleImageDelete={handleImageDelete} handleImageUploadParent={handleImageUpload} handleImageReorder={handleImageReorder} handleImageDropSave={handleImageDropSave} />
            </TabPane>
          )}
          <TabPane id="agent-count-stats" activeTab={activeTab}>
            <AgentCounterStats counterStats={agent.counterStats} handleCounterStatsChange={handleCounterStatsChanges} />
          </TabPane>
          <TabPane id="agent-social" activeTab={activeTab}>
            <AgentLinksContainer
              agentOther={agent.otherLinks}
              agentOtherErrors={agent.errors.other || {}}
              handleOtherAdd={handleOtherAdd}
              handleOtherDelete={handleOtherDelete}
              agentReview={agent.reviewLinks}
              handleReviewChanges={handleReviewChanges}
              agentSocial={agent.social}
              agentSocialErrors={agent.errors.social || {}}
              handleSocialChanges={handleSocialChanges}
            />
          </TabPane>
          <TabPane id="agent-testimonials" activeTab={activeTab}>
            <AgentTestimonials agentID={agentID} errors={agent.errors.testimonials || {}} testimonials={agent.testimonials} handleOnChange={handleTestimonialChanges} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
  return null;
};

export default AgentEdit;
