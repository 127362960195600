//address actions
export const FETCHING_COUNTRIES = "FETCHING_COUNTRIES";
export const FETCHED_COUNTRIES = "FETCHED_COUNTRIES";
export const FETCH_COUNTRIES_ERROR = "FETCH_COUNTRIES_ERROR";

export const FETCHING_STATES = "FETCHING_STATES";
export const FETCHED_STATES = "FETCHED_STATES";
export const FETCH_STATES_ERROR = "FETCH_STATES_ERROR";

export const FETCHING_CITIES = "FETCHING_CITIES";
export const FETCHED_CITIES = "FETCHED_CITIES";
export const FETCH_CITIES_ERROR = "FETCH_CITIES_ERROR";
//end address actions

//icon actions
export const FETCHING_ICONS = "FETCHING_ICONS";
export const FETCHED_ICONS = "FETCHED_ICONS";
export const FETCH_ICONS_ERROR = "FETCH_ICONS_ERROR";
//end icon actions

//auth actions
export const LOGIN_IS_FETCHING = "LOGIN_IS_FETCHING";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_TOKEN_IS_VALID = "LOGIN_TOKEN_IS_VALID";
//end auth actions

//roster actions
export const UPDATING_SHOW_ON_ROSTER_AGENT = "UPDATING_SHOW_ON_ROSTER_AGENT";
export const UPDATE_SHOW_ON_ROSTER_AGENT_ERROR = "UPDATE_SHOW_ON_ROSTER_AGENT_ERROR";
export const UPDATED_SHOW_ON_ROSTER_AGENT = "UPDATED_SHOW_ON_ROSTER_AGENT";

export const UPDATING_ENABLE_PROFILE_PAGE_AGENT = "UPDATING_ENABLE_PROFILE_PAGE_AGENT";
export const UPDATE_ENABLE_PROFILE_PAGE_AGENT_ERROR = "UPDATE_ENABLE_PROFILE_PAGE_AGENT_ERROR";
export const UPDATED_ENABLE_PROFILE_PAGE_AGENT = "UPDATED_ENABLE_PROFILE_PAGE_AGENT";

export const DELETING_ROSTER_AGENT = "DELETING_ROSTER_AGENT";
export const DELETE_ROSTER_AGENT_ERROR = "DELETE_ROSTER_AGENT_ERROR";
export const DELETED_ROSTER_AGENT = "DELETED_ROSTER_AGENT";

export const UPDATING_FEATURED_AGENT = "UPDATING_FEATURED_AGENT";
export const UPDATE_FEATURE_AGENT_ERROR = "UPDATE_FEATURE_AGENT_ERROR";
export const UPDATED_FEATURE_AGENT = "UPDATED_FEATURE_AGENT";

export const LOAD_ROSTER_AGENTS_FETCHING = "LOAD_ROSTER_AGENTS_FETCHING";
export const LOAD_ROSTER_AGENTS_ERROR = "LOAD_ROSTER_AGENTS_ERROR";
export const LOADED_ROSTER_AGENTS = "LOADED_ROSTER_AGENTS";

export const LOAD_ROSTER_OFFICES_FETCHING = "LOAD_ROSTER_OFFICES_FETCHING";
export const LOAD_ROSTER_OFFICES_ERROR = "LOAD_ROSTER_OFFICES_ERROR";
export const LOADED_ROSTER_OFFICES = "LOADED_ROSTER_OFFICES";

export const LOAD_ROSTER_SETTINGS_FETCHING = "LOAD_ROSTER_SETTINGS_FETCHING";
export const LOAD_ROSTER_SETTINGS_ERROR = "LOAD_ROSTER_SETTINGS_ERROR";
export const LOADED_ROSTER_SETTINGS = "LOADED_ROSTER_SETTINGS";

export const ROSTER_SETTINGS_SAVING = "ROSTER_SETTINGS_SAVING";
export const SAVE_ROSTER_SETTINGS_ERROR = "SAVE_ROSTER_SETTINGS_ERROR";
export const SAVED_ROSTER_SETTINGS = "SAVED_ROSTER_SETTINGS";
//end roster actions
