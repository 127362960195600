import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import AgentTestimonial from "./AgentTestimonial";
import { toast } from "react-toastify";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import { useSelector } from "react-redux";

const AgentTestimonials = ({ agentID, testimonials, handleOnChange, errors }) => {
  const [newItemCounter, setNewItemCounter] = useState(1);
  const authToken = useSelector((state) => state.auth.authUser.token);

  const style = {
    backgroundColor: "white",
    border: "0px",
  };

  let newTestimonialObj = { id: `newTestimonial`, title: "", body: "", client_name: "" };

  const handleOnTestimonialChange = useCallback(
    (testimonial) => {
      let found = false;
      const newTestimonials = testimonials.map((testi) => {
        if (testi.id === testimonial.id) {
          found = true;
          return testimonial;
        }
        return testi;
      });
      if (!found) {
        newTestimonials.push(testimonial);
      }
      handleOnChange(newTestimonials);
    },
    [handleOnChange, testimonials]
  );

  const handleOnTestimonialDelete = useCallback(
    (id) => {
      const newTestimonials = testimonials.filter((testi) => {
        if (testi.id === id) {
          return false;
        }
        return testi;
      });
      handleOnChange(newTestimonials);

      if (!isNaN(id)) {
        fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/testimonial/${id}`, fetchOptions(authToken, "DELETE"))
          .then(
            (response) => response.json(),
            (error) => {
              console.log("An error occurred deleting the testimonial.", error);
              toast.error("An error occurred deleting the testimonial." + error);
            }
          )
          .then((json) => {
            if (!json.status) {
              console.log("Error deleting testimonial");
              toast.error("Error deleting testimonial.");
            }
          })
          .catch(function (e) {
            toast.error(e);
            console.log(e);
          });
      }
    },
    [handleOnChange, testimonials, agentID, authToken]
  );

  const handleAddTestimonialClick = () => {
    let tempList = testimonials;
    const newCounter = newItemCounter;
    newTestimonialObj.id = `newTestimonial${newCounter}`;
    tempList.push(newTestimonialObj);
    setNewItemCounter(newCounter + 1);
    handleOnChange(tempList);
  };

  const testimonialsComps = useMemo(() => {
    if (testimonials.length === 0) {
      return <AgentTestimonial errors={{}} key="newTestimonial" id="newTestimonial" clientName="" title="" body="" handleOnChange={handleOnTestimonialChange} handleOnDelete={handleOnTestimonialDelete} />;
    } else {
      return testimonials.map((testimonial, index) => {
        const testimonialErrors = errors && [index] in errors ? errors[index] : {};
        return <AgentTestimonial errors={testimonialErrors} key={testimonial.id} id={testimonial.id} clientName={testimonial.client_name} title={testimonial.title} body={testimonial.body} handleOnChange={handleOnTestimonialChange} handleOnDelete={handleOnTestimonialDelete} />;
      });
    }
  }, [testimonials, handleOnTestimonialChange, handleOnTestimonialDelete, errors]);

  return (
    <>
      <div className="testimonials-wrapper">{testimonialsComps}</div>
      <div className="add-btn m-t-10">
        <button onClick={handleAddTestimonialClick} style={style} className="add-button flex ai-center blue pointer link testimonial-trigger">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <span>Add Testimonial</span>
        </button>
      </div>
    </>
  );
};

AgentTestimonials.defaultProps = {
  testimonials: [],
};

AgentTestimonials.propTypes = {
  /**
   * br edit agent id
   */
  agentID: PropTypes.string.isRequired,
  /**
   * array of testimonial objects
   */
  testimonials: PropTypes.array.isRequired,
  /**
   * function to handle a testimonial change on parent list
   */
  handleOnChange: PropTypes.func.isRequired,
};

export default AgentTestimonials;
