import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import RosterList from "./components/roster/RosterList";
import AgentEdit from "./components/agent/AgentEdit";
import Logmein from "./components/auth/Logmein";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/controls/PrivateRoute";
import PreferredLender from "./components/preferredLender/PreferredLender";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Switch>
          <Route path="/logmein/:token" component={Logmein} />
          <PrivateRoute path="/roster-list" component={RosterList} />
          <PrivateRoute path="/preferred-lender" component={PreferredLender} />
          <PrivateRoute path="/agent/:id" component={AgentEdit} />
          <PrivateRoute path="/" component={RosterList} />
        </Switch>
        <ToastContainer hideProgressBar />
      </div>
    </BrowserRouter>
  );
}

export default App;
