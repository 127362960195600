import React, { useState } from "react";
import PhotoUpload from "../controls/PhotoUpload";
import PropTypes from "prop-types";
import EmailForm from "../email/EmailForm";
import PhoneForm from "../phone/PhoneForm";
import { SITEBUILDER_URL } from "../../store/actions/common";

/**
 * Component for showing the preferred lender edit/add view.
 *
 * @component
 */
const LenderInfo = ({ lender, handleParentOnChange, handleOnDeleteClick }) => {

  const isEdit = !!(lender?.id && lender?.id > 0);
  const [firstName, setFirstName] = useState(lender?.firstName || "");
  const [lastName, setLastName] = useState(lender?.lastName || "");
  const [email, setEmail] = useState(lender?.email || { id: 0, email: "", type: "cellular" });
  const [title, setTitle] = useState(lender?.title || "");
  const [website, setWebsite] = useState(lender?.website || "");
  const [phone, setPhone] = useState(lender?.phone || { id: 0, number: "", type: "work" });
  const [photoUrl, setPhotoUrl] = useState(lender?.photoUrl || "");

  const uploadPhotoUrl = `${SITEBUILDER_URL}/api/agent/${lender.userID}/preferred-lender/${lender.id}/image`;

  const handlePhotoChange = (newImage) => {
    let newLender = lender;
    newLender.photoUrl = newImage;
    setPhotoUrl(newImage);
    handleParentOnChange({ ...newLender });
  };

  const handlePhoneChange = (values) => {
    setPhone(values);
    handleParentOnChange({ ...lender, phone: values });
  };
  const handleEmailChange = (values) => {
    setEmail(values);
    handleParentOnChange({ ...lender, email: values });
  };

  const handleOnChange = (event) => {
    handleParentOnChange({ ...lender, [event.target.id]: event.target.value });
  };

  const settingsClass = lender.id > 0 ? "info-group col-md-8" : "info-group col-md-12";

  return (
    <div className="info-group-container bg-white p-t-20 m-b-20">
      <div className="info-group-col">
        {lender.id > 0 && (
          <div className="info-group col-md-4">
            <div className="header">
              <div className="header-label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span>Main Photo</span>
              </div>
            </div>
            <div className="data-collection">
              <div className="input-group">
                <div className="input-wrapper">
                  <PhotoUpload mainPhoto={photoUrl} handleImageUploadParent={handlePhotoChange} uploadUrl={uploadPhotoUrl} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={settingsClass}>
          <div className="header">
            <div className="header-label">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Name Settings</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <label htmlFor="first-name">First Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    handleOnChange(event);
                  }}
                  id="firstName"
                  placeholder="Enter First Name"
                  value={firstName}
                />
                {lender.errors.firstName && <div className="error">{lender.errors.firstName}</div>}
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="last-name">Last Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setLastName(event.target.value);
                    handleOnChange(event);
                  }}
                  id="lastName"
                  placeholder="Enter Last Name"
                  value={lastName}
                />
                {lender.errors.lastName && <div className="error">{lender.errors.lastName}</div>}
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setTitle(event.target.value);
                    handleOnChange(event);
                  }}
                  id="title"
                  placeholder="Ex. Owner"
                  value={title}
                />
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="website">Website</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setWebsite(event.target.value);
                    handleOnChange(event);
                  }}
                  id="website"
                  placeholder="https://brivity.com"
                  value={website}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-group-col">
        <div id="phone-wrapper" className="info-group m-0 m-b-30 col-md-12">
          <PhoneForm phone={phone} errors={lender.errors.phone ? { number: lender.errors.phone } : {}} handleOnChange={handlePhoneChange} isEdit={false} handleOnDelete={() => {}} />
        </div>
        <div id="email-wrapper" className="info-group m-0 m-b-30 col-md-12">
          <EmailForm email={email} errors={lender.errors.email ? { email: lender.errors.email } : {}} isEdit={false} handleOnChange={handleEmailChange} handleOnDelete={() => {}} />
        </div>
      </div>
      {isEdit && (
          <div className="div-delete-lender" onClick={handleOnDeleteClick}>
            <button className="btn btn-danger icon-btn">
              <svg className="delete action" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
              <span>Delete Lender</span>
            </button>
          </div>
      )}
    </div>
  );
};

LenderInfo.propTypes = {
  lender: PropTypes.object.isRequired,
  handleParentOnChange: PropTypes.func.isRequired,
};

export default LenderInfo;
