export let phoneTypes =  [
                                { name:'Home', value:'home' },
                                { name:'Work', value:'work' },
                                { name:'Cellular', value:'cellular' },
                                { name:'Personal', value:'personal' },
                                { name:'Other', value:'other' },
                            ];

export let emailTypes =  [
                                { name:'Home', value:'home' },
                                { name:'Work', value:'work' },
                                { name:'Personal', value:'personal' },
                                { name:'Other', value:'other' },
];

export let addressTypes =  [
                                { name:'Home', value:'home' },
                                { name:'Work', value:'work' },
                                { name:'Mailing', value:'mailing' },
                                { name:'Other', value:'other' },
];

