import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const addressReducer = (state = initialState.address, action) => {
  switch (action.type) {
    case types.FETCHING_COUNTRIES:
      return {
        ...state,
        countriesFetching: true,
        countriesFetchError: false,
        countriesFetched: false,
      };
    case types.FETCH_COUNTRIES_ERROR:
      return {
        ...state,
        countriesFetching: false,
        countriesFetchError: true,
        countriesFetched: false,
      };
    case types.FETCHED_COUNTRIES:
      return {
        ...state,
        countriesFetching: false,
        countriesFetchError: false,
        countriesFetched: true,
        countries: action.countries,
      };
    case types.FETCHING_STATES:
      return {
        ...state,
        statesFetching: true,
        statesFetchError: false,
        statesFetched: false,
      };
    case types.FETCH_STATES_ERROR:
      return {
        ...state,
        statesFetching: false,
        statesFetchError: true,
        statesFetched: false,
      };
    case types.FETCHED_STATES:
      return {
        ...state,
        statesFetching: false,
        statesFetchError: false,
        statesFetched: true,
        states: action.states,
      };
    case types.FETCHING_CITIES:
      return {
        ...state,
        citiesFetching: true,
        citiesFetchError: false,
        citiesFetched: false,
      };
    case types.FETCH_CITIES_ERROR:
      return {
        ...state,
        citiesFetching: false,
        citiesFetchError: true,
        citiesFetched: false,
      };
    case types.FETCHED_CITIES:
      return {
        ...state,
        citiesFetching: false,
        citiesFetchError: false,
        citiesFetched: true,
        cities: action.cities,
      };
    default:
      return state;
  }
};

export default addressReducer;
