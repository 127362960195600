import React, { useCallback, useState, useMemo } from "react";
import DragAndDropFileInput from "../controls/DragAndDropFileInput";
import ImageList from "../controls/ImageList";
import cuid from "cuid";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

/**
 * photos of the agent section
 *
 * @component
 */

const AgentMedia = ({ media, handleImageDelete, handleImageUploadParent, handleImageReorder, handleImageDropSave, agentID }) => {
  const authToken = useSelector((state) => state.auth.authUser.token);
  const [image, setImage] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage((prevState) => [...prevState, { id: cuid(), src: e.target.result }]);
      };
      reader.readAsDataURL(file);
      setUploadedFiles((prevState) => [...prevState, file]);
      return file;
    });
  }, []);

  const handleImageUpload = (uploadedImage) => {
    let position = media.length;
    let uploaded = media.length;
    const images = [...uploadedFiles];
    //setUploadedFiles([]);
    images.forEach((uploadedImage) => {
      let postOptions = fetchOptions(authToken, "POST");
      let data = new FormData();
      data.append("agentimage", uploadedImage);
      data.append("position", position);
      position++;
      postOptions["body"] = data;

      setIsUploading(true);
      setErrorUploading(false);

      return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/image`, postOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            handleImageUploadParent(data.data);
            uploaded++;
            if (uploaded === position) {
              setImage([]);
              setUploadedFiles([]);
              setIsUploading(false);
            }
          } else {
            toast.error("Error uploading image.");
            setIsUploading(false);
            setErrorUploading(true);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(`Error uploading image ${error}`);
          setIsUploading(false);
          setErrorUploading(true);
        });
    });
  };

  const draggedImages = useMemo(() => {
    if (image.length === 0) {
      return null;
    }
    const uploadedImgStyle = {
      maxHeight: "200px",
      maxWidth: "300px",
      marginRight: "10px",
    };
    return image.map((val) => {
      return <img key={val.id} style={uploadedImgStyle} alt={`img - ${val.id}`} src={val.src} className="file-img" />;
    });
  }, [image]);

  // simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
  const isTouchDevice = () => {
    if ("ontouchstart" in window) {
      return true;
    }
    return false;
  };
  // Assigning backend based on touch support on the device
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

  return (
    <div className="agent-media-content">
      <div className="agent-media-upload">
        <h3>Upload new image</h3>
        {image.length === 0 ? (
          <DragAndDropFileInput onDrop={onDrop} accept={"image/*"} maxSize={10485760} multiple={true} dragText="Drag 'n' drop some files here, or click to select files" />
        ) : (
          <div className="m-t-15">
            {draggedImages}
            {isUploading ? (
              <img width="150px" src="https://cdn1.brivityidx.com/assets/images/Spinner-1s-200px.svg" alt="Uploading" />
            ) : (
              <span>
                <button className="btn-primary btn m-l-15 m-r-15" onClick={handleImageUpload}>
                  Upload
                </button>
                <button
                  className="btn-primary btn"
                  onClick={() => {
                    setImage([]);
                    setErrorUploading(false);
                  }}
                >
                  Cancel
                </button>
                {errorUploading && <h5 style={{ color: "red" }}>Error uploading image, please try again!!</h5>}
              </span>
            )}
          </div>
        )}
      </div>

      <DndProvider backend={backendForDND}>
        <ImageList images={media} handleImageDelete={handleImageDelete} handleImageReorder={handleImageReorder} handleImageDropSave={handleImageDropSave} />
      </DndProvider>
    </div>
  );
};

AgentMedia.propTypes = {
  /**
   * list of images
   * [
   *  {id: "1", position: "0", s3_url: "https://urloftheimage.com/1"},
   *  {id: "2", position: "1", s3_url: "https://urloftheimage.com/2""}
   * ]
   */
  media: PropTypes.array.isRequired,
  /**
   * function that handles the image delete
   */
  handleImageDelete: PropTypes.func.isRequired,
  /**
   * function that handles the image reorder ui logic
   */
  handleImageReorder: PropTypes.func.isRequired,
  /**
   * function that handles the image reorder save
   */
  handleImageDropSave: PropTypes.func.isRequired,
  /**
   * id of the agent being edited
   */
  agentID: PropTypes.string.isRequired,
  /**
   * function that handles the image upload
   */
  handleImageUploadParent: PropTypes.func.isRequired,
};

export default AgentMedia;
