import { validEmail, validUrl } from "./Common";

export const validateOtherLinkAdd = (title, url) => {
  let response = {};
  if (!title || title === "") {
    response.title = "Title is required";
  }
  if (!url || url === "") {
    response.url = "Title is required";
  } else if (!validUrl(url)) {
    response.url = "Url is invalid";
  }
  return response;
};

export const validateAgent = (agent) => {
  agent.errors = {};
  if (!agent.about.firstName || agent.about.firstName === "") {
    agent.errors.firstName = "First name is required";
  }
  if (!agent.about.lastName || agent.about.lastName === "") {
    agent.errors.lastName = "Last name is required";
  }

  let phonesErrors = validateBlock(agent.phones, [
    { field: "number", errorLabel: "Phone" },
    { field: "type", errorLabel: "Type" },
  ]);
  if (Object.keys(phonesErrors).length !== 0 && phonesErrors.constructor === Object) {
    agent.errors.phones = phonesErrors;
  }

  let testimonialsErrors = validateBlock(agent.testimonials, [
    { field: "client_name", errorLabel: "Name" },
    { field: "body", errorLabel: "Testimonial Text" },
    { field: "title", errorLabel: "Title" },
  ]);
  if (Object.keys(testimonialsErrors).length !== 0 && testimonialsErrors.constructor === Object) {
    agent.errors.testimonials = testimonialsErrors;
  }

  let emailsErrors = validateBlock(agent.emails, [
    { field: "email", errorLabel: "Email" },
    { field: "type", errorLabel: "Type" },
  ]);
  if (Object.keys(emailsErrors).length !== 0 && emailsErrors.constructor === Object) {
    agent.errors.emails = emailsErrors;
  }

  let addressesErrors = validateBlock(agent.addresses, [
    { field: "streetAddress", errorLabel: "Street Address" },
    { field: "zip", errorLabel: "Zip" },
    { field: "type", errorLabel: "Type" },
    { field: "state", errorLabel: "state" },
    { field: "country", errorLabel: "country" },
    { field: "city", errorLabel: "city" },
  ]);
  if (Object.keys(addressesErrors).length !== 0 && addressesErrors.constructor === Object) {
    agent.errors.addresses = addressesErrors;
  }

  let socialErrors = validateSocial(agent.social, [
    { field: "facebook", errorLabel: "Facebook url" },
    { field: "twitter", errorLabel: "Twitter url" },
    { field: "instagram", errorLabel: "Instagram url" },
    { field: "pinterest", errorLabel: "Pinterest url" },
    { field: "linkedin", errorLabel: "Linkedin url" },
    { field: "youtube", errorLabel: "Youtube url" },
  ]);

  if (Object.keys(socialErrors).length !== 0) {
    agent.errors.social = socialErrors;
  }

  return agent.errors;
};

const validateSocial = (socialObj, fields) => {
  let response = {};
  //socialList.forEach( (data) => {
  fields.forEach((field) => {
    if (socialObj[field.field] && socialObj[field.field] !== "" && !validUrl(socialObj[field.field])) {
      response[field.field] = field.errorLabel + " is invalid";
    }
  });
  //});
  return response;
};

const validateBlock = (blockList, fields) => {
  let response = {};
  blockList.forEach((data, index) => {
    let errors = {};
    fields.forEach((field) => {
      if (!data[field.field] || data[field.field] === "") {
        errors[field.field] = field.errorLabel + " is required";
      } else {
        switch (field.field) {
          case "email":
            if (!validEmail(data[field.field])) {
              errors[field.field] = field.errorLabel + " is invalid";
            }
            break;
          case "number":
            if (data[field.field].length !== 10) {
              errors[field.field] = field.errorLabel + " is invalid";
            }
            break;
          default:
            break;
        }
      }
    });
    if (Object.keys(errors).length !== 0) {
      //response.push(errors);
      response[index] = errors;
    }
  });
  return response;
};
