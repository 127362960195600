import React, { useState } from "react";
import Dropdown from "../controls/Dropdown";
import ModalDialog from "../controls/ModalDialog";
import RosterSettings from "../roster/RosterSettings";
import AgentAdd from "../agent/AgentAdd";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

/**
 * Toolbar for the roster list page
 *
 * @component
 */
const RosterToolbar = ({ offices, selected, isOfficeLoading, isSettingsSaving, handleOfficeChange, handleRosterSettingsSave, order, handleSearch }) => {
  let officesList = [];
  const history = useHistory();
  const selectedVal = selected === "" ? "all" : selected;

  if (!isOfficeLoading) {
    officesList = officesList.concat([{ name: "All Offices", value: "all" }], offices);
  }

  const [showRosterSettingsModal, setShowRosterSettingsModal] = useState(false);
  const [showAddAgentModal, setShowAddAgentModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleRosterSettingsClose = () => {
    setShowRosterSettingsModal(false);
  };

  const handleAgentAddClose = () => {
    setShowAddAgentModal(false);
  };

  const handleRosterSettingsOnClick = () => {
    setShowRosterSettingsModal(true);
  };
  const handlePreferredLenderOnClick = () => {
    history.push("/preferred-lender");
  };
  const handleAddAgentOnClick = () => {
    setShowAddAgentModal(true);
  };

  const displaySettingsModal = (
    <ModalDialog show={showRosterSettingsModal} handleClose={handleRosterSettingsClose} title="Roster Display Setting">
      <RosterSettings isSettingsSaving={isSettingsSaving} handleOnClose={handleRosterSettingsClose} handleOnSave={handleRosterSettingsSave} order={order} />
    </ModalDialog>
  );
  const addAgentModal = (
    <ModalDialog show={showAddAgentModal} handleClose={handleAgentAddClose} title="Add New Agent">
      <AgentAdd handleClose={handleAgentAddClose} />
    </ModalDialog>
  );

  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch(e.target.value);
    }
  };

  const handleOnSearchClick = () => {
    handleSearch(searchTerm);
  };

  return (
    <div className="toolbar">
      <div className="toolbar-left">
        <div className="search-bar m-r-15">
          <input
            name="search_term"
            type="text"
            placeholder="Search Agents"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onKeyDown={handleOnKeyPress}
          />
          <div className="search-btn" onClick={handleOnSearchClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-search"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>
        </div>
        <Dropdown title="Offices" list={officesList} handleOnChange={handleOfficeChange} isLoading={isOfficeLoading} isLoaded={!isOfficeLoading} name="office-location" selected={selectedVal} />

        {displaySettingsModal}
        {addAgentModal}
      </div>
      <div className="toolbar-right">
        <button className="icon-btn m-r-15" onClick={handleRosterSettingsOnClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-settings"
          >
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
          </svg>
          <span>Roster Display Settings</span>
        </button>
        <button className="btn-primary btn icon-btn m-r-15" onClick={handlePreferredLenderOnClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-user"
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          <span>Preferred Lender</span>
        </button>

        <button className="btn-primary btn icon-btn m-r-15" onClick={handleAddAgentOnClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-plus-circle"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <span>Add Agent</span>
        </button>
      </div>
    </div>
  );
};

RosterToolbar.propTypes = {
  /**
   * array of offices for the toolbar dropdown
   */
  offices: PropTypes.array.isRequired,
  /**
   * office that is selected from the offices list
   */
  selected: PropTypes.string.isRequired,
  /**
   * prop that indicates if the offices list is being loaded
   */
  isOfficeLoading: PropTypes.bool.isRequired,
  /**
   * prop that indicates if the roster list settings is saving
   */
  isSettingsSaving: PropTypes.bool.isRequired,
  /**
   * function that handles the office dropdown select change
   */
  handleOfficeChange: PropTypes.func.isRequired,
  /**
   * function that handles the roster settings change
   */
  handleRosterSettingsSave: PropTypes.func.isRequired,
  /**
   * function that handles a search on the roster agents
   */
  handleSearch: PropTypes.func.isRequired,
};

export default RosterToolbar;
