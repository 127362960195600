import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import PropTypes from "prop-types";

/**
 * Image list with drag and drop support, uses react-dnd
 *
 * @component
 * @example
 * const images = [{"id":"63","s3_url":"https:\/\/s3-us-west-2.amazonaws.com\/a-staging.blueroof360.com\/assets\/images\/agent-photos\/1\/0\/6\/10615754\/Screen_Shot_2020-04-28_at_9_23_52_AM.png","position":"0"}]
 * return (
 *   <ImageList images={images} handleImageDelete={()=>{}} handleImageReorder={()=>{}} handleImageDropSave={()=>{}} />
 * )
 */
// ImageList Component
const ImageList = ({ images, handleImageDelete, handleImageReorder, handleImageDropSave }) => {
  // render each image by calling Image component

  const renderImage = (image, index) => {
    return <Image image={image} key={`${image.id}-image`} index={index} handleImageDelete={handleImageDelete} handleImageReorder={handleImageReorder} handleImageDrop={handleImageDropSave} />;
  };

  // Return the list of files
  return <div className="photos-wrapper m-t-20">{images.map(renderImage)}</div>;
};

ImageList.propTypes = {
  /**
   * array of images
   * ex [{id:1,s3_url:"https://urlofimage.com",position:0}]
   */
  images: PropTypes.array.isRequired,
  /**
   * function that handles the delete of an image
   */
  handleImageDelete: PropTypes.func.isRequired,
  /**
   * function that handles the image drag and reorder
   */
  handleImageReorder: PropTypes.func.isRequired,
  /**
   * function that handles the the new order save
   */
  handleImageDropSave: PropTypes.func.isRequired,
};

// Rendering individual images
const Image = ({ image, index, handleImageDelete, handleImageReorder, handleImageDrop }) => {
  const type = "Image";
  const ref = useRef(null);

  // useDrop hook is responsible for handling whether any item gets hovered or dropped on the element
  const [, drop] = useDrop({
    // Accept will make sure only these element type can be droppable on this element
    accept: type,
    drop(item) {
      //console.log("save reorder");
      handleImageDrop();
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;

      // current element where the dragged element is hovered on
      const hoverIndex = index;
      // If the dragged element is hovered in the same place, then do nothing
      if (dragIndex === hoverIndex) {
        return;
      }
      // If it is dragged around other elements, then move the image and set the state with position changes
      handleImageReorder(dragIndex, hoverIndex);
      /*
                Update the index for dragged item directly to avoid flickering
                when the image was half dragged into the next
            */
      item.index = hoverIndex;
    },
  });

  // useDrag will be responsible for making an element draggable. It also expose, isDragging method to add any styles while dragging
  const [{ isDragging }, drag] = useDrag({
    // item denotes the element type, unique identifier (id) and the index (position)
    item: { type, id: image.id, index },
    // collect method is like an event listener, it monitors whether the element is dragged and expose that information
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  /* 
        Initialize drag and drop into the element using its reference.
        Here we initialize both drag and drop on the same element (i.e., Image component)
    */
  drag(drop(ref));

  const rowStyle = {
    backgroundImage: "url('" + image.s3_url + "')",
    opacity: isDragging ? 0 : 1,
  };
  return (
    <div ref={ref} className="img" style={rowStyle}>
      <div className="img-actions flex ai-start jc-between p-10">
        <svg className="move action" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <polyline points="5 9 2 12 5 15"></polyline>
          <polyline points="9 5 12 2 15 5"></polyline>
          <polyline points="15 19 12 22 9 19"></polyline>
          <polyline points="19 9 22 12 19 15"></polyline>
          <line x1="2" y1="12" x2="22" y2="12"></line>
          <line x1="12" y1="2" x2="12" y2="22"></line>
        </svg>
        <svg onClick={() => handleImageDelete(image.id)} className="delete action" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      </div>
    </div>
  );
};

export default ImageList;
