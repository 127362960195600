import React, { useState, useEffect } from "react";
import PhoneForm from "../phone/PhoneForm";
import EmailForm from "../email/EmailForm";
import AddressForm from "../address/AddressForm";
import AgentOffices from "./AgentOffices";
import { useSelector, useDispatch } from "react-redux";
import { fetchCountries, fetchStates } from "../../store/actions/addressActions";
import fetch from "cross-fetch";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import PropTypes from "prop-types";
import AgentMainPhoto from "./AgentMainPhoto";
import AgentSubdomain from "./AgentSubdomain";
import { toast } from "react-toastify";

/**
 * Agent about section on the edit agent page
 *
 * @component
 */
const AgentAbout = ({ agent, isEdit, handleAboutChange, handleShowOnRoster, handleEnableProfilePage }) => {
  const [firstName, setFirstName] = useState(agent.about.firstName);
  const [lastName, setLastName] = useState(agent.about.lastName);
  const [mainPhoto, setMainPhoto] = useState(agent.about.mainPhoto);
  const [title, setTitle] = useState(agent.about.title);
  const [subdomain, setSubdomain] = useState(agent.about.subdomain);
  const [keywords, setKeywords] = useState(agent.about.keywords);
  const [biography, setBiography] = useState(agent.about.biography);
  const [showBio, setShowBio] = useState(agent.about.showBio);
  const [favorites, setFavorites] = useState(agent.about.favorites);
  const [qualifications, setQualifications] = useState(agent.about.qualifications);
  const [agentMlsID, setAgentMlsID] = useState(agent.about.agentMlsID);
  const [videoUrl, setVideoUrl] = useState(agent.about.videoUrl);
  const [website, setWebsite] = useState(agent.about.website);
  const [licenseID, setLicenseID] = useState(agent.about.licenseID);
  const [addingOffice, setAddingOffice] = useState(false);
  const [about] = useState(agent.about);
  const [showOnRoster] = useState(agent.about.showOnRoster);
  const [officeList, setOfficeList] = useState(agent.offices);

  useEffect(() => {
    setOfficeList(agent.offices);
  }, [agent.offices]);

  const [phoneList, setPhoneList] = useState(agent.phones);
  useEffect(() => {
    setPhoneList(agent.phones);
  }, [agent.phones]);

  const [emailList, setEmailList] = useState(agent.emails);
  useEffect(() => {
    setEmailList(agent.emails);
  }, [agent.emails]);

  const [addressList, setAddressList] = useState(agent.addresses);
  useEffect(() => {
    setAddressList(agent.addresses);
  }, [agent.addresses]);

  const [newItemCounter, setNewItemCounter] = useState(1);

  const authToken = useSelector((state) => state.auth.authUser.token);
  const countriesFetching = useSelector((state) => state.address.countriesFetching);
  const countriesFetched = useSelector((state) => state.address.countriesFetched);
  const countriesFetchError = useSelector((state) => state.address.countriesFetchError);
  const countryList = useSelector((state) => state.address.countries);
  const statesFetching = useSelector((state) => state.address.statesFetching);
  const statesFetched = useSelector((state) => state.address.statesFetched);
  const statesFetchError = useSelector((state) => state.address.statesFetchError);
  const stateList = useSelector((state) => state.address.states);
  const dispatch = useDispatch();

  const newAddressObj = { id: `newAddress`, city: "", streetAddress: "", zip: "", state: "1", state_name: "Utah", country: "1", country_name: "United States", type: "work" };

  const handleOnChange = (event) => {
    handleAboutChange({ ...agent.about, [event.target.id]: event.target.value }, phoneList, emailList, addressList, officeList);
  };

  const handlePhoneChange = (phoneObj) => {
    let tempList = updateList(phoneList, phoneObj);
    setPhoneList(tempList);
    handleAboutChange({ ...agent.about }, tempList, emailList, addressList, officeList);
  };

  const handleOfficeAdd = (officeObj) => {
    let tempList = officeList;
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].office_id === officeObj.office_id) {
        toast.info("Office is already added");
        return;
      }
    }
    setAddingOffice(true);
    let postOptions = fetchOptions(authToken, "POST");
    postOptions["body"] = new FormData();

    return fetch(`${SITEBUILDER_URL}/api/agent/${agent.id}/office/${officeObj.office_id}`, postOptions)
      .then(
        (response) => response.json(),
        (error) => {
          toast.error("An error occurred adding the office." + error);
          setAddingOffice(false);
        }
      )
      .then((json) => {
        if (!json.status) {
          toast.error("Error adding office." + json.msg);
        } else {
          tempList.push({ id: json.data.id, office_id: officeObj.office_id, office_name: officeObj.office_name });
          setOfficeList(tempList);
          handleAboutChange({ ...agent.about }, phoneList, emailList, addressList, tempList);
          toast.success("Office added.");
        }
        setAddingOffice(false);
      })
      .catch(function (e) {
        toast.error("Error adding office.");
        setAddingOffice(false);
      });
  };

  const handleOfficeDelete = (officeObj) => {
    let tempList = removeFromList(officeList, officeObj.id);
    fetch(`${SITEBUILDER_URL}/api/agent/${agent.id}/office/${officeObj.office_id}`, fetchOptions(authToken, "DELETE"))
      .then(
        (response) => response.json(),
        (error) => {
          toast.error("An error occurred deleting the office." + error);
        }
      )
      .then((json) => {
        if (json.status) {
          setOfficeList(tempList);
          handleAboutChange({ ...agent.about }, phoneList, emailList, addressList, tempList);
        } else {
          toast.error("error deleting office");
        }
      })
      .catch(function (e) {
        toast.error(e);
      });
  };

  const handlePhoneDelete = (phoneID) => {
    let tempList = removeFromList(phoneList, phoneID);
    if (isNaN(phoneID) && phoneID.indexOf("newPhone") !== -1) {
      setPhoneList(tempList);
      handleAboutChange({ ...agent.about }, tempList, emailList, addressList, officeList);
    } else {
      fetch(`${SITEBUILDER_URL}/api/agent/${agent.id}/phone/${phoneID}`, fetchOptions(authToken, "DELETE"))
        .then(
          (response) => response.json(),
          (error) => {
            toast.error("An error occurred deleting the phone." + error);
          }
        )
        .then((json) => {
          if (json.status) {
            setPhoneList(tempList);
            handleAboutChange({ ...agent.about }, tempList, emailList, addressList, officeList);
          } else {
            toast.error("Error deleting phone");
          }
        })
        .catch(function (e) {
          toast.error("Error deleting phone");
        });
    }
  };

  const handleEmailChange = (emailObj) => {
    let tempList = updateList(emailList, emailObj);
    setEmailList(tempList);
    handleAboutChange({ ...agent.about }, phoneList, tempList, addressList, officeList);
  };

  const handleEmailDelete = (emailID) => {
    let tempList = removeFromList(emailList, emailID);
    if (emailID.indexOf("newEmail") !== -1) {
      setEmailList(tempList);
      handleAboutChange({ ...agent.about }, phoneList, tempList, addressList, officeList);
    } else {
      fetch(`${SITEBUILDER_URL}/api/agent/${agent.id}/email/${encodeURIComponent(emailID)}`, fetchOptions(authToken, "DELETE"))
        .then(
          (response) => response.json(),
          (error) => {
            toast.error("An error occurred deleting the email." + error);
          }
        )
        .then((json) => {
          if (json.status) {
            setEmailList(tempList);
            handleAboutChange({ ...agent.about }, phoneList, tempList, addressList, officeList);
          } else {
            toast.error("error deleting email");
          }
        })
        .catch(function (e) {
          toast.error(e);
        });
    }
  };

  const handleShowBioOnChange = () => {
    const newShowBio = !showBio;
    setShowBio(newShowBio);
    handleAboutChange({ ...agent.about, showBio: newShowBio }, phoneList, emailList, addressList, officeList);
  };

  const handleMainPhotoChange = (newImage) => {
    let newAbout = agent.about;
    newAbout.mainPhoto = newImage;
    setMainPhoto(newImage);
    handleAboutChange({ ...newAbout }, phoneList, emailList, addressList, officeList);
  };

  const handleAddressChange = (addressObj) => {
    let tempList = updateList(addressList, addressObj);
    setAddressList(tempList);
    handleAboutChange({ ...agent.about }, phoneList, emailList, tempList, officeList);
  };

  const handleAddressDelete = (addressID) => {
    let tempList = removeFromList(addressList, addressID);
    if (isNaN(addressID) && addressID.indexOf("newAddress") !== -1) {
      setAddressList(tempList);
      handleAboutChange({ ...agent.about }, phoneList, emailList, tempList, officeList);
    } else {
      fetch(`${SITEBUILDER_URL}/api/agent/${agent.id}/address/${encodeURIComponent(addressID)}`, fetchOptions(authToken, "DELETE"))
        .then(
          (response) => response.json(),
          (error) => {
            toast.error("An error occurred deleting address." + error);
          }
        )
        .then((json) => {
          if (json.status) {
            setAddressList(tempList);
            handleAboutChange({ ...agent.about }, phoneList, emailList, tempList, officeList);
          } else {
            toast.error("error deleting address");
          }
        })
        .catch(function (e) {
          toast.error(e);
        });
    }
  };

  const handleAddPhoneClick = () => {
    let tempList = phoneList;
    const newCounter = newItemCounter;
    tempList.push({ id: `newPhone${newCounter}`, number: "", type: "" });
    setNewItemCounter(newCounter + 1);
    setPhoneList(tempList);
    handleAboutChange({ ...agent.about }, tempList, emailList, addressList, officeList);
  };

  const handleAddEmailClick = () => {
    let tempList = emailList;
    const newCounter = newItemCounter;
    tempList.push({ id: `newEmail${newCounter}`, number: "", type: "" });
    setNewItemCounter(newCounter + 1);
    setEmailList(tempList);
    handleAboutChange({ ...agent.about }, phoneList, tempList, addressList);
  };

  const handleAddAddressClick = () => {
    let tempList = addressList;
    const newCounter = newItemCounter;
    tempList.push(newAddressObj);
    setNewItemCounter(newCounter + 1);
    setAddressList(tempList);
    handleAboutChange({ ...agent.about }, phoneList, emailList, tempList, officeList);
  };

  const removeFromList = (list, id) => {
    return list.filter((item) => {
      if (item.id === id) {
        return false;
      }
      return true;
    });
  };

  const updateList = (list, updatedObj) => {
    let newList = [];

    if (list.length === 0) {
      newList.push(updatedObj);
    } else {
      newList = list.map((item) => {
        if (item.id === updatedObj.id) {
          item = updatedObj;
        }
        return item;
      });
    }
    return newList;
  };

  let tempPhoneList = phoneList;
  if (tempPhoneList.length === 0) {
    tempPhoneList.push({ id: "newPhone", number: "", type: "cellular" });
  }
  let phoneForms = tempPhoneList.map((phone, index) => {
    let phoneError = agent.errors.phones && [index] in agent.errors.phones ? agent.errors.phones[index] : {};
    return <PhoneForm key={phone.id} phone={phone} errors={phoneError} isEdit={isEdit} handleOnChange={handlePhoneChange} handleOnDelete={handlePhoneDelete} />;
  });

  let emailForms = emailList.map((email, index) => {
    let emailError = agent.errors.emails && [index] in agent.errors.emails ? agent.errors.emails[index] : {};
    return <EmailForm key={email.id} email={email} errors={emailError} isEdit={isEdit} handleOnChange={handleEmailChange} handleOnDelete={handleEmailDelete} />;
  });

  if (!countriesFetched && !countriesFetching && !countriesFetchError) {
    dispatch(fetchCountries(authToken));
  }

  if (countriesFetched && !statesFetched && !statesFetching && !statesFetchError) {
    let countryIds = new Set(["1"]);
    for (let i = 0; i < countryList.length; i++) {
      countryIds.add(countryList[i]["value"]);
    }
    dispatch(fetchStates([...countryIds], authToken));
  }

  let tempAddressList = [...addressList];
  if (tempAddressList.length === 0) {
    tempAddressList.push(newAddressObj);
  }
  let addressForms = tempAddressList.map((address, index) => {
    let addressError = agent.errors.addresses && [index] in agent.errors.addresses ? agent.errors.addresses[index] : {};
    return (
      <AddressForm
        key={address.id}
        address={address}
        countryList={countryList}
        isCountryLoading={countriesFetching}
        stateList={stateList}
        isStateLoading={countriesFetching || statesFetching}
        errors={addressError}
        isEdit={isEdit}
        handleOnChange={handleAddressChange}
        handleOnDelete={handleAddressDelete}
      />
    );
  });

  return (
    <div className="info-group-container">
      <div className="info-group-col">
        <div className="info-group col-md-4">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Main Photo</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <AgentMainPhoto mainPhoto={mainPhoto} handleImageUploadParent={handleMainPhotoChange} agentID={agent.id} />
              </div>
            </div>
          </div>
          <div className="show-on-site">
            <div className={`switch ${showOnRoster ? "active" : ""}`} style={{float:'right'}} onClick={() => handleShowOnRoster(about, agent.id, !showOnRoster)} >
               Show On Roster List&nbsp;
              <div className="switch-bg">
                <div className="switch-handel"></div>
              </div>
            </div>
              <div className={`switch ${showBio ? "active" : ""}`} disabled={ showOnRoster ? "" : "true"} style={{float:'right', marginTop:'10px'}} onClick={() => handleEnableProfilePage(about, agent.id, !showBio)} >
                  Active Profile Page&nbsp;
                  <div className="switch-bg">
                      <div className="switch-handel"></div>
                  </div>
              </div>
          </div>
        </div>
        <div className="info-group col-md-8">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Name Settings</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <label htmlFor="first-name">First Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                    handleOnChange(event);
                  }}
                  id="firstName"
                  placeholder="Enter First Name"
                  value={firstName}
                />
                {agent.errors.firstName && <div className="error">{agent.errors.firstName}</div>}
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="last-name">Last Name*</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setLastName(event.target.value);
                    handleOnChange(event);
                  }}
                  id="lastName"
                  placeholder="Enter Last Name"
                  value={lastName}
                />
                {agent.errors.lastName && <div className="error">{agent.errors.lastName}</div>}
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setTitle(event.target.value);
                    handleOnChange(event);
                  }}
                  id="title"
                  placeholder="Ex. Owner"
                  value={title}
                />
              </div>
            </div>
            <AgentSubdomain
              subdomainID={agent.about.subdomainID}
              subdomain={subdomain}
              domain={agent.about.brokerageDomain}
              handleOnChange={(event) => {
                setSubdomain(event.target.value);
                handleOnChange(event);
              }}
            />
          </div>
        </div>

        <div id="phone-wrapper" className="info-group m-0 m-b-30 col-md-12">
          {phoneForms}
        </div>

        {isEdit && (
          <div onClick={handleAddPhoneClick} className="add-btn m-b-30 add-phone-agent col-md-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
            <span>Add Phone</span>
          </div>
        )}
        <div id="email-wrapper" className="info-group m-0 m-b-30 col-md-12">
          {emailForms}
        </div>
        {isEdit && (
          <div onClick={handleAddEmailClick} className="add-btn m-b-30 add-email-agent col-md-12">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
            <span>Add Email</span>
          </div>
        )}
        <div id="address-wrapper" className="info-group m-0 m-b-30 col-md-12">
          {addressForms}
        </div>
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Agent Info</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group">
              <div className="input-wrapper">
                <label htmlFor="agentMlsID">Agent MLS ID:</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setAgentMlsID(event.target.value);
                    handleOnChange(event);
                  }}
                  id="agentMlsID"
                  placeholder="1234567"
                  value={agentMlsID}
                />
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="videoUrl">Introduction Video:</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setVideoUrl(event.target.value);
                    handleOnChange(event);
                  }}
                  id="videoUrl"
                  placeholder="Video URL..."
                  value={videoUrl}
                />
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="website">Website:</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setWebsite(event.target.value);
                    handleOnChange(event);
                  }}
                  id="website"
                  placeholder="Website URL..."
                  value={website}
                />
              </div>
            </div>
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <label htmlFor="licenseID">License ID #:</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setLicenseID(event.target.value);
                    handleOnChange(event);
                  }}
                  id="licenseID"
                  placeholder="License ID #"
                  value={licenseID}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="info-group-col">
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Offices</span>
            </div>
          </div>
          <div className="data-collection">
            <AgentOffices agentID={agent.id} offices={[]} agentOffices={agent.offices} isAdding={addingOffice} handleOnAdd={handleOfficeAdd} handleOnDelete={handleOfficeDelete} />
          </div>
        </div>
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Keywords</span>
            </div>
          </div>
          <div className="data-collection">
            <textarea
              id="keywords"
              onChange={(event) => {
                setKeywords(event.target.value);
                handleOnChange(event);
              }}
              placeholder="Seperate keywords by commas"
              data-bind="value: remarks"
              value={keywords}
            />
          </div>
        </div>
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Agent Bio</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group m-t-15">
              <div className="input-wrapper">
                <textarea
                  id="biography"
                  onChange={(event) => {
                    setBiography(event.target.value);
                    handleOnChange(event);
                  }}
                  placeholder="Enter agent bio"
                  data-bind="value: remarks"
                  value={biography}
                />
              </div>
            </div>
            <div className="input-group m-t-15" style={{display:'none'}}>
              <div className="input-wrapper">
                <label>
                  <input name="showBioCheckBox" type="checkbox" checked={showBio} onChange={handleShowBioOnChange} />
                  &nbsp;Show Agent Bio
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Favorites Things</span>
            </div>
          </div>
          <div className="data-collection">
            <textarea
              id="favorites"
              onChange={(event) => {
                setFavorites(event.target.value);
                handleOnChange(event);
              }}
              placeholder="Things that you’re interested in, hobbies, etc."
              data-bind="value: remarks"
              value={favorites}
            />
          </div>
        </div>
        <div className="info-group col-md-12">
          <div className="header">
            <div className="header-label">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Qualifications</span>
            </div>
          </div>
          <div className="data-collection">
            <textarea
              id="qualifications"
              onChange={(event) => {
                setQualifications(event.target.value);
                handleOnChange(event);
              }}
              placeholder="Enter agent qualifications here"
              data-bind="value: remarks"
              value={qualifications}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AgentAbout.defaultProps = {
  errors: {},
};

AgentAbout.propTypes = {
  /**
   * object for an agent
   */
  agent: PropTypes.object.isRequired,
  /**
   * object of errors
   * {email: "Email is required", type: "Type is required"}
   */
  errors: PropTypes.object.isRequired,
  /**
   * used to display or hide the delete button
   */
  isEdit: PropTypes.bool.isRequired,
  /**
   * function to handle the change and update props
   */
  handleAboutChange: PropTypes.func.isRequired,
  /**
   * function that handles display or hide of an agent in the roster list
   */
  handleShowOnRoster: PropTypes.func.isRequired,
  /**
   * function that handles display or hide of an agent in the Profile Page
   */
  handleEnableProfilePage: PropTypes.func.isRequired,
};

export default AgentAbout;
