import React from "react";
import PropTypes from "prop-types";

/**
 * links section of an agent edit
 *
 * @component
 */

const AgentLinks = ({ links, handleLinksChanges, title, icon, errors }) => {
  const handleOnChange = (title, newVal) => {
    const newState = links.map((val) => {
      if (val.title === title) {
        val.url = newVal;
      }
      return val;
    });
    handleLinksChanges(newState);
  };

  const style = {
    padding: "5px",
    width: "28px",
    height: "28px",
  };

  const findEntry = (entry) => {
    for (let i = 0; i < links.length; i++) {
      if (links[i].title === entry) {
        return links[i].url;
      }
    }
    return "";
  };

  const inputs = links.map((entry, index) => {
    return (
      <div className="input-wrapper" key={index}>
        <label htmlFor={entry.title}>{entry.title.charAt(0).toUpperCase() + entry.title.slice(1)}</label>
        <input
          placeholder="Enter URL..."
          type="text"
          name={entry.title}
          onChange={(e) => {
            handleOnChange(entry.title, e.target.value);
          }}
          value={findEntry(entry.title)}
        />
        {errors[entry.title] && <div className="error">{errors[entry.title]}</div>}
      </div>
    );
  });

  return (
    <div className="info-group-container m-b-30">
      <div className="info-group-col">
        <div className="info-group">
          <div className="header">
            <div className="header-label">
              <img src={icon} alt={title} style={style} />
              <span>{title}</span>
            </div>
          </div>
          <div className="data-collection">
            <div className="input-group m-b-30">
              {inputs[0] || null}
              {inputs[1] || null}
            </div>
            <div className="input-group m-b-30">
              {inputs[2] || null}
              {inputs[3] || null}
            </div>
            <div className="input-group m-b-30">
              {inputs[4] || null}
              {inputs[5] || null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AgentLinks.propTypes = {
  /**
   * [{id: "3", icon: "fa-skype", title: "Blueroof", url: "blueroof360.com"},...]
   * }
   */
  links: PropTypes.array.isRequired,
  /**
   * function that handles the image reorder save
   */
  handleLinksChanges: PropTypes.func.isRequired,
  /**
   * possible errors on input data
   */
  errors: PropTypes.object.isRequired,
  /**
   * display title and alt
   */
  title: PropTypes.string.isRequired,
  /**
   * path to icon to use on label
   */
  icon: PropTypes.string.isRequired,
};

export default AgentLinks;
