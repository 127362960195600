import React, { useCallback, useState } from "react";
import DragAndDropFileInput from "../controls/DragAndDropFileInput";
import { SITEBUILDER_URL, fetchOptions } from "../../store/actions/common";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

/**
 * handles main photo upload
 *
 * @component
 */

const AgentMainPhoto = ({ mainPhoto, handleImageUploadParent, agentID }) => {
  const authToken = useSelector((state) => state.auth.authUser.token);
  const [image, setImage] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorUploading, setErrorUploading] = useState(false);

  const uploadedImgStyle = {
    maxHeight: "164px",
    maxWidth: "300px",
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage((prevState) => [...prevState, { src: e.target.result }]);
      };
      reader.readAsDataURL(file);
      setUploadedFile(file);
      return file;
    });
  }, []);

  const handleImageUpload = (uploadedImage) => {
    const formData = new FormData();
    formData.append("agentimage", uploadedImage);

    let postOptions = fetchOptions(authToken, "POST");
    let data = new FormData();
    data.append("agentimage", uploadedImage);
    postOptions["body"] = data;

    setIsUploading(true);
    setErrorUploading(false);

    return fetch(`${SITEBUILDER_URL}/api/agent/${agentID}/image?is_main=true`, postOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          handleImageUploadParent(data.data.s3_url);
          setImage([]);
          setUploadedFile(null);
          setIsUploading(false);
        } else {
          toast.error("Error uploading photo.");
          setErrorUploading(true);
        }
      })
      .catch((error) => {
        toast.error(error);
        setErrorUploading(true);
      });
  };

  const fileInputExtraStyle = {
    height: "203px",
    backgroundImage: `url(${mainPhoto})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    border: "0",
  };

  const dragTextStyle = {
    backgroundColor: "white",
    paddingTop: "12px",
    opacity: "0.3",
    marginTop: "42%",
  };

  return (
    <div className="agent-media-content">
      <div className="agent-media-upload">
        {image.length === 0 ? (
          <DragAndDropFileInput onDrop={onDrop} accept={"image/*"} maxSize={10485760} multiple={false} extraStyle={fileInputExtraStyle} dragTextStyle={dragTextStyle} dragText="Drag 'n' drop an image to replace, or click to select files" />
        ) : (
          <>
            {isUploading ? (
              <div className="text-center m-t-30 m-b-30">
                <img width="150px" src="https://cdn1.brivityidx.com/assets/images/Spinner-1s-200px.svg" alt="Uploading" />
              </div>
            ) : (
              <div className="text-center">
                <img style={uploadedImgStyle} alt={`img - ${image[0].id}`} src={image[0].src} className="file-img" />
                <div className="m-t-10">
                  <button className="btn-primary btn m-l-15 m-r-15" onClick={() => handleImageUpload(uploadedFile)}>
                    Upload
                  </button>
                  <button
                    className="btn-primary btn"
                    onClick={() => {
                      setImage([]);
                      setErrorUploading(false);
                    }}
                  >
                    Cancel
                  </button>
                  {errorUploading && <h5 style={{ color: "red" }}>Error uploading image, please try again!!</h5>}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

AgentMainPhoto.propTypes = {
  /**
   * string with main photo url
   */
  mainPhoto: PropTypes.string,
  /**
   * id of the agent being edited
   */
  agentID: PropTypes.string.isRequired,
  /**
   * function that handles the image upload
   */
  handleImageUploadParent: PropTypes.func.isRequired,
};

export default AgentMainPhoto;
