import React from "react";
import Navgreeting from "./Navgreeting";
import Navlink from "./Navlink";

/**
 * Top nav bar for the builder
 *
 * @component
 */

const Navbar = (props) => {
  let phpSitebuilderUrl = process.env.REACT_APP_SITEBUILDER_URL;

  return (
    <nav className="mainnav navbar" role="navigation" id="main-nav">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#main-nav-collapse">
          <span className="sr-only">Toggle Navigation</span>
        </button>
        <a className="navbar-brand" href="/">
          <img alt="logo" src="/images/realvolution-icon.png" />
        </a>
      </div>
      <div className="collapse navbar-collapse" id="main-nav-collapse">
        <ul className="nav navbar-nav">
          <Navlink title="Sites" link={`${phpSitebuilderUrl}/`} icon="/images/icons/monitor.svg" />
          <Navlink title="Blog" link={`${phpSitebuilderUrl}/blog`} icon="/images/icons/edit.svg" />
          <Navlink title="Images" link={`${phpSitebuilderUrl}/assets/images`} icon="/images/icons/image.svg" />
          <Navlink title="Listings" link={`${phpSitebuilderUrl}/manage-listings`} icon="/images/icons/home.svg" />
          <Navlink title="Roster" link="/roster-list" icon="/images/icons/list.svg" active={true} reactRouter={true} />
          <Navlink title="Users" link={`${phpSitebuilderUrl}/users`} icon="/images/icons/users.svg" />
          <Navlink title="Migrate" link={`${phpSitebuilderUrl}/sites/migrate`} icon="/images/icons/arrow-up.svg" />
        </ul>
        <Navgreeting />
      </div>
    </nav>
  );
};

export default Navbar;
