import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Toolbar for with back url
 *
 * @component
 */

const BackToolbar = ({ title, backLink, isSaving, handleOnSaveClick }) => {
  return (
    <div className="toolbar">
      <div className="toolbar-left">
        <Link to={backLink} className="back-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="15 18 9 12 15 6" />
          </svg>
          <span>Back</span>
        </Link>
        <h2>{title}</h2>
      </div>
      <div className="toolbar-right">
        {isSaving ? (
          <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn" disabled>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-save"
            >
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg>
            <span>Saving...</span>
          </button>
        ) : (
          <button onClick={handleOnSaveClick} className="btn-primary btn icon-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-save"
            >
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg>
            <span>Save</span>
          </button>
        )}
      </div>
    </div>
  );
};

BackToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  handleOnSaveClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};
export default BackToolbar;
