import React from "react";
import PropTypes from "prop-types";

/**
 * handles the subdomain section of edit agent
 *
 * @component
 */
const AgentSubdomain = ({ subdomainID, subdomain, domain, handleOnChange }) => {
  if (domain === "") {
    return null;
  }

  return (
    <div className="input-group m-t-15" style={{display:'none'}}>
      <div className="input-wrapper flex2">
        <label htmlFor="title">Subdomain</label>
        <input type="hidden" id="subdomainID" value={subdomainID} />
        <input type="text" onChange={(event) => handleOnChange(event)} id="subdomain" placeholder="agentsubdomain" value={subdomain} />
        <span style={{ fontSize: "12px" }}>Changes to subdomain will take a few minutes to process.</span>
      </div>
      <div className="input-wrapper">
        <div className="m-t-30">.{domain}</div>
      </div>
    </div>
  );
};

AgentSubdomain.defaultProps = {
  subdomainID: 0,
  subdomain: "",
  domain: "",
};

AgentSubdomain.propTypes = {
  /**
   * id of the subdomain
   */
  subdomainID: PropTypes.number.isRequired,
  /**
   * subdomain part of url
   */
  subdomain: PropTypes.string.isRequired,
  /**
   * domain part of the url
   */
  domain: PropTypes.string.isRequired,
  /**
   * function to handle an on change event
   */
  handleOnChange: PropTypes.func.isRequired,
};

export default AgentSubdomain;
