import React, { useState } from "react";
import PropTypes from "prop-types";

/**
 * Body of the roster settings modal, to choose roster display settings
 *
 * @component
 */
const RosterSettings = ({ order = "", handleOnClose, handleOnSave, isSettingsSaving }) => {
  const [checkedOption, setCheckedOption] = useState(order);
  const handleDisplaySettingsChange = (value) => {
    setCheckedOption(value);
  };

  const options = [
    { id: 0, title: "Random", value: "random|asc" },
    { id: 1, title: "First Name: A-Z", value: "first_name|asc" },
    { id: 2, title: "First Name: Z-A", value: "first_name|desc" },
    { id: 3, title: "Last Name: A-Z", value: "last_name|asc" },
    { id: 4, title: "Last Name: Z-A", value: "last_name|desc" },
    { id: 5, title: "By Date Added: Ascending", value: "created|asc" },
    { id: 6, title: "By Date Added: Descending", value: "created|desc" },
  ];

  const rows = options.map((row) => {
    return (
      <li className={row.id % 2 === 0 ? `roster-settings-border` : null} key={row.id}>
        <label className="new-radio">
          {row.title}
          <input type="radio" name="display-setting" value={row.value} checked={row.value === checkedOption} onChange={() => handleDisplaySettingsChange(row.value)} />
          <span className="radio-indicator"></span>
        </label>
      </li>
    );
  });

  return (
    <div>
      <ul className="display-setting-options m-b-40">{rows}</ul>
      {/* <div className="flex jc-center m-b-20">
            <a className="button" href="/roster-list/arrange-roster">
              <button href="" className="icon-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                <span>Customize</span>
              </button>
            </a>
          </div>
          <div className="flex jc-center m-b-40">
            <span className="customize-text">This will allow you to drag and drop agents in a specific order.</span>
          </div> */}
      <div className="flex jc-center">
        <button onClick={handleOnClose} className="icon-btn m-r-15">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus-circle">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="16"></line>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
          <span>Cancel</span>
        </button>
        <button disabled={isSettingsSaving} onClick={() => handleOnSave(checkedOption)} className="btn-primary btn icon-btn">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-save">
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
            <polyline points="17 21 17 13 7 13 7 21"></polyline>
            <polyline points="7 3 7 8 15 8"></polyline>
          </svg>
          <span>{isSettingsSaving ? "Saving..." : "Save"}</span>
        </button>
      </div>
    </div>
  );
};

RosterSettings.propTypes = {
  /**
   * option selected
   */
  order: PropTypes.string.isRequired,
  /**
   * function that handles the modal close on button click
   */
  handleOnClose: PropTypes.func.isRequired,
  /**
   * function that handles the settings save
   */
  handleOnSave: PropTypes.func.isRequired,
  /**
   * prop that indicates if the roster settings is saving, disables and changes save button text
   */
  isSettingsSaving: PropTypes.bool.isRequired,
};

export default RosterSettings;
